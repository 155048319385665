import React, { useEffect, useState } from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import DashboardLayout from '../../../layouts/DashboardLayout';
import {
  Button,
  Input,
  Layout,
  Space,
  Table,
  Tooltip,
  Row,
  Menu,
  Col,
  Form,
  Dropdown,
  Checkbox,
  Badge,
} from "antd";
import { AppstoreOutlined, FolderOpenOutlined, TeamOutlined, MessageOutlined, SettingOutlined, EditOutlined, UserOutlined, DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import styled from 'styled-components'
import axiosApi, { API } from '../../../lib/utils/axiosApi';
import { setModalFilter } from "../../../redux/servicesDuck";
import { getAllWorkFlow } from "../../../redux/workflowDuck";
import { getUserProfiles } from "../../../redux/userProfileDuck";
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from "react-intl";
import { changeLangAction, getBrandsAction, getModelsAction } from '../../../redux/settingsDuck';
import {
  DashboardOutlined,
  DownOutlined,
  WechatOutlined,
  FileTextOutlined,
  LineChartOutlined,
  MobileOutlined,
} from "@ant-design/icons";
import moment from 'moment';
import FilterSalesModal from "../../../components/modal/FilterSalesModal";
import TabsOptions from "../../../components/dashboard/TabsOptions";
import { Global, css } from "@emotion/react";
import _ from "lodash"
import fileIcon from "../../../image/icons/archivo azul.svg"
import ventasIconBlanco from "../../../image/ventas_icon2.svg"
import ventasIconAzul from "../../../image/ventas_icon.svg"
import chatIcon from "../../../image/chat_icon.svg"
import KButton from "../../../components/common/elements/KButton/KButton";

const csvData = [
  ["firstname", "lastname", "email"],
  ["Ahmed", "Tomi", "ah@smthing.co.com"],
  ["Raed", "Labes", "rl@smthing.co.com"],
  ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];

const CustomTable = styled(Table)`
  border-color: grey;

  @media only screen and (min-width: 1200px) {
    .ant-table-cell {
      font-size: 14px;
    }
  }

  .ant-table-cell {
    background-color: rgb(240, 242, 245);
    border-color: #dbdbdb;
    font-size: 13px;
  }
  .ant-table-thead > tr > th {
    color: #341eff;
    white-space: nowrap;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px;
  }
  .ant-table-tbody > tr > td {
    color: #08082f;
    white-space: nowrap;
  }
`;



const ProceedingsPage = ({ changeLangAction, intl, settings, setModalFilter, getBrandsAction, getModelsAction, sales, getAllWorkFlow, getUserProfiles, ...props }) => {
  // Constante para usar el router de react.
  const history = useHistory();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [saleOrders, setSaleOrders] = useState([]);
  const [saleOrdersCSV, setSaleOrdersCSV] = useState([]);
  const [pagination, setPagination] = useState({
    showSizeChanger: true,
    current: 1,
    pageSize: 20,
  });

  const useCounter = () => {
    const [trueSomething, setTrueSomenthing] = useState(false);

    const changeTrue = () => setTrueSomenthing(!trueSomething);

    return {
      trueSomething,
      changeTrue
    }
  }

  const FiltroFolio = useCounter();
  const FiltroMarca = useCounter();
  const FiltroModelo = useCounter();
  const FiltroAsesor = useCounter();
  const FiltroCliente = useCounter();
  const FiltroFAlta = useCounter();
  const FiltroStatus = useCounter();
  const FiltroAction = useCounter();

  const OpenClose = useCounter();


  // Declaramos las columnas de la tabla.
  const columns = [
    {
      title: "Folio",
      dataIndex: ["order"],
      key: "folio",
      hidden: FiltroFolio.trueSomething,
      render: (text, record) => (
        <a
          onClick={() => {
            history.push(`/sale-orders/tracking/${record.order.id}`);
          }}
        >
          {record.order.folio}
        </a>
      ),
    },
    {
      title: <FormattedMessage id="layout.brand" />,
      dataIndex: ["car_model", "brand", "name"],
      key: "mark",
      hidden: FiltroMarca.trueSomething,
    },
    {
      title: <FormattedMessage id="layout.model" />,
      dataIndex: ["car_model", "name"],
      key: "car",
      hidden: FiltroModelo.trueSomething,
    },
    {
      title: <FormattedMessage id="services.page.advisor" />,
      dataIndex: ["order", "assigned_to"],
      key: "asesor",
      hidden: FiltroAsesor.trueSomething,

      render: (record) => {
        return record ? `${record.first_name} ${record.last_name}` : "--";
      },
    },
    {
      title: <FormattedMessage id="services.page.costumer" />,
      dataIndex: ["order", "customer"],
      key: "client",
      hidden: FiltroCliente.trueSomething,
      render: (record) => {
        return `${record.first_name} ${record.last_name}`;
      },
    },
    {
      title: "F. Recepción",
      key: "created_at",
      dataIndex: ["order", "created_at"],
      width: 200,
      hidden: FiltroFAlta.trueSomething,
      render: (record) => {
        let date = new Date(record);
        return moment(date).format("DD/MM/YYYY");
      },
    },
    {
      title: "F. Creación",
      key: "timestamp",
      dataIndex: ["order", "timestamp"],
      width: 200,
      hidden: FiltroFAlta.trueSomething,
      render: (record) => {
        let date = new Date(record);
        return moment(date).format("DD/MM/YYYY");
      },
    },
    /* {
      title: "F. ingreso",
      key: "date of admission",
      dataIndex: "delivery_date",
    }, */
    /* {
      title: "F. promesa",
      key: "date_promise",
      dataIndex: "delivery_date",
      render: (date) => {
        return date ? moment(date).format("DD/MM/YYYY") : "";
      },
    },
    {
      title: "Facturado",
      key: "invoiced",
    }, */
    {
      title: <FormattedMessage id="services.page.status" />,
      dataIndex: ["order", "current_step", "step", "name"],
      key: "delivery_status",
      hidden: FiltroStatus.trueSomething,
      render: (text) => (
        <>{text !== "" && <span className="badgeStatus">{text}</span>}</>
      ),
    },
    {
      title: <FormattedMessage id="layout.actions" />,
      key: "action",
      hidden: FiltroAction.trueSomething,
      render: (text, record) => (
        <Dropdown overlay={menu(record)}>
          <Button className="btn-blue btn-blue-hove" size="small">
            <FormattedMessage id="layout.actions" /> <DownOutlined />
          </Button>

          {/* <Space size="middle">
            <Tooltip title="Editar">
              <Button
                type="primary"
                size="large"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
            <a href={`/sale-orders/tracking/${record.order.id}`}>Editar</a>
            <Tooltip title="Eliminar">
              <Button
                type="danger"
                size="large"
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          </Space> */}
        </Dropdown>
      ),
    },
  ].filter((item) => !item.hidden);//filter(element => element.key !== 'action', 'folio')

  const changePagination = (data) => {
    getData(data.current, data.pageSize);
    /* setPagination({..pagination, current: data.current }) */
    /* dispatch({
      type: "EXTRA_ACTION",
      payload: { paginationActive: data },
    });
    dispatch(getUsersActives()); */
  };

  const menu = (record) => (
    <Menu>
      <Menu.Item
        key="1"
        icon={<UserOutlined />}
        onClick={() => history.push(`/sale-orders/tracking/${record.order.id}`)}
      >
        <FormattedMessage id="layout.edit" />
      </Menu.Item>
      <Menu.Item
        key="2"
        //icon={<WechatOutlined />}
        onClick={() => history.push(`/service-chat/${record.order.id}`)}
      >
        <img src={chatIcon} width="17px" height="17px" /> &nbsp; Chat
      </Menu.Item>
      <Menu.Item
        key="3"
        //icon={<WechatOutlined />}
        onClick={() => history.push(`/services-files/${record.order.id}`)}
      >
        <img src={fileIcon} width="17px" height="17px" />
        <FormattedMessage id="services.files.title" />
      </Menu.Item>
    </Menu>
  );

  const menuFiltros = () => (
    <Menu>
      <Menu.Item key="1">
        <Checkbox
          onChange={FiltroFolio.changeTrue}
          checked={!FiltroFolio.trueSomething}
        >
          Folio
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="2">
        <Checkbox
          onChange={FiltroMarca.changeTrue}
          checked={!FiltroMarca.trueSomething}
        >
          <FormattedMessage id="layout.brand" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="3">
        <Checkbox
          onChange={FiltroModelo.changeTrue}
          checked={!FiltroModelo.trueSomething}
        >
          <FormattedMessage id="layout.model" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="4">
        <Checkbox
          onChange={FiltroAsesor.changeTrue}
          checked={!FiltroAsesor.trueSomething}
        >
          <FormattedMessage id="services.page.advisor" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="5">
        <Checkbox
          onChange={FiltroCliente.changeTrue}
          checked={!FiltroCliente.trueSomething}
        >
          <FormattedMessage id="services.page.costumer" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="6">
        <Checkbox
          onChange={FiltroFAlta.changeTrue}
          checked={!FiltroFAlta.trueSomething}
        >
          F. Alta
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="7">
        <Checkbox
          onChange={FiltroStatus.changeTrue}
          checked={!FiltroStatus.trueSomething}
        >
          <FormattedMessage id="services.page.status" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="8">
        <Checkbox
          onChange={FiltroAction.changeTrue}
          checked={!FiltroAction.trueSomething}
        >
          {" "}
          <FormattedMessage id="layout.actions" />
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  const getData = async (page, page_size, search = "",
    callbackLoading = setLoading,
    callback = setSaleOrders, setPaginationFlag = true) => {
    try {
      callbackLoading(true);

      const start_date = sales.filterData?.start_date ? `${sales.filterData?.start_date} 00:00:00` : '';
      const end_date = sales.filterData?.end_date ? `${sales.filterData?.end_date} 23:59:59` : '';
      const filter = `&car_model__brand=${sales.filterData?.brand}&car_model=${sales.filterData?.model}&order__current_step__step__id=${sales.filterData?.order__current_step}&sale_type=${sales.filterData?.sale_type}&start_order__timestamp=${start_date}&end_order__timestamp=${end_date}&order__asigned_to__id=${sales.filterData?.asigned_to}`;


      const response = await axiosApi.get(
        API.SALE.SALE_ORDER +
        `?page=${page}&page_size=${page_size}&search=${search}${filter}`
      );
      /* if(pagination.pageSize !== page_size){
        page =1;
      } */
      if (setPaginationFlag) {
        setPagination({
          ...pagination,
          total: response.data.count,
          current: page,
          pageSize: page_size,
        });
      }
      callback(response.data.results);
      setModalFilter(false);
    } catch (error) {
      console.log("Error al cargar datos", error);
    } finally {
      callbackLoading(false);
    }
  };

  const processSaleOrderCSV = (dataIn) => {
    const headers = ['Folio', 'Marca', 'Modelo', 'Asesor', 'Cliente', 'F. Alta', 'Estado'];
    let newData = _.map(dataIn, (f) => {
      return [
        f.order.folio,
        f.car_model.brand.name,
        f.car_model.name,
        `${f.order?.asigned_to && f.order.asigned_to.first_name} ${f.order?.asigned_to && f.order.asigned_to.last_name}`,
        `${f.order?.customer && f.order.customer.first_name} ${f.order?.customer && f.order.customer.last_name}`,
        moment(f.order.timestamp).format("DD/MM/YYYY"),
        f.order?.current_step?.step?.name
      ]
    })
    newData.unshift(headers)
    setSaleOrdersCSV(newData)
  };

  useEffect(() => {
    getData(pagination.current, pagination.pageSize);
    getData(1, 10000, '', setLoadingCSV, processSaleOrderCSV, false);
    getAllWorkFlow();
    getUserProfiles();
  }, []);

  /* const filter = (values) => {
    console.log(values)
    if (values.search) {
      getData(pagination.current, pagination.pageSize, values.search);
    }
  } */

  const onClear = _.debounce((value) => {
    getData(1, pagination.pageSize, value);
  }, 1000)

  /* const onClear =  _(value) => {
    getData(1, pagination.pageSize);
    getData(1, pagination.pageSize, value);
  } */

  useEffect(() => {
    getBrandsAction();
    getModelsAction()
  }, [])

  const [active, setActive] = useState(null);



  return (
    <>
      <Global
        styles={css`
          .badgeStatus {
            background: #2f8eed1a;
            border-radius: 5px;
            color: #2d2cf5 !important;
            padding: 5px 10px;
          }
          .spaceTable{
            margin-top: 10px;
          }
        `}
      />
      <DashboardLayout
        title={intl.formatMessage({ id: "services.service.saleOrders" })}
        index="ordenes_de_venta"
      >
        <Layout style={{ marginTop: 10 }}>
          {/*<TabsOptions />*/}
          {/*<Col span={23} style={{ textAlign: "left" }}>*/}
          {/*  <Button*/}
          {/*    htmlType="button"*/}
          {/*    onClick={() => history.push("/sale-orders")}*/}
          {/*    className="btn-string-blue-low btn-report-action"*/}
          {/*    onMouseEnter={() => setActive(0)} onMouseLeave={() => setActive(null)}*/}
          {/*  >*/}
          {/*    <img src={active === 0 ? ventasIconAzul : ventasIconBlanco} width="20px" height="20px" /> &nbsp; &nbsp; <FormattedMessage id="services.sale" />*/}
          {/*  </Button>*/}
          {/*</Col>*/}
          <Row gutter={[10]} justify="space-between" style={{ marginTop: 20 }}>
            <Col flex="auto">
              <Form size="large" /* onFinish={filter} */>
                <Form.Item className="mb-0" name="search">
                  <Input
                    size="large"
                    prefix={<SearchOutlined />}
                    placeholder={intl.formatMessage({
                      id: "services.page.file",
                    })}
                    className="br-10"
                    style={{ height: 50 }}
                    allowClear
                    /* onChange={(e) => onClear(e)} */
                    onChange={(e) => onClear(e.target.value)}
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col>
              <Space direction="horizontal">
                <KButton size={'large'} text={<FormattedMessage id="layout.createNew" />} onClick={() => history.push("/sale-orders/new")} />
                <KButton size={'large'} type={'secondary'}
                  text={
                    loadingCSV ? <><FormattedMessage id="salesOrder.loading" /></> : <CSVLink
                      filename={"sales.csv"}
                      //className="btn-blue btn-report-action"
                      data={saleOrdersCSV}>
                      <FormattedMessage id="services.page.report" />
                    </CSVLink>
                  } />
                <KButton size={'large'} type={'default'} text={<FormattedMessage id="services.page.filter" />} onClick={() => setModalFilter(true)} />

              </Space>
            </Col>
          </Row>
          <div style={{ marginTop: 100 }}>
            {/*<Col span={23} style={{ textAlign: "right" }}>*/}
            {/*  <Dropdown overlay={menuFiltros} className="spaceTable" trigger="click" placement="bottomRight" visible={OpenClose.trueSomething}>*/}
            {/*    <Button onClick={OpenClose.changeTrue}>*/}
            {/*      <SettingOutlined />*/}
            {/*    </Button>*/}
            {/*  </Dropdown>*/}
            {/*</Col>*/}
            <CustomTable
              columns={columns}
              loading={loading}
              dataSource={saleOrders} z
              onChange={changePagination}
              pagination={pagination}
            />
          </div>
        </Layout>
        <FilterSalesModal getData={getData} />
      </DashboardLayout>
    </>
  );
};

const mapState = (state) => ({
  servicesStatus: state.services,
  settings: state.settings,
  sales: state.sales,
  workflow: state.workflow,
});

export default injectIntl(
  connect(mapState, {
    setModalFilter,
    changeLangAction,
    getBrandsAction,
    getModelsAction,
    getAllWorkFlow,
    getUserProfiles,
  })(ProceedingsPage)
);
