import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Layout, Card, Avatar, Row, Col, Typography, Modal, Input, message, Timeline, Spin, Image, Button } from 'antd';
import moment from 'moment';
import 'moment/locale/es';
import styled from '@emotion/styled';
import { axiosApi } from '../../../lib/utils/axiosApi';
import KButton from "../../../components/common/elements/KButton/KButton";
import turn from "../../../image/turn_icon_kikert.svg";
import { CheckCircleFilled, ClockCircleOutlined, FileOutlined, PhoneOutlined, SearchOutlined, WhatsAppOutlined } from '@ant-design/icons';
import { keyframes } from '@emotion/react';

const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

const StyledLayout = styled(Layout)`
  min-height: 100vh;
`;

const StyledHeader = styled(Header)`
  background-color: #2a0dd3 !important;
  padding: 0 20px;
  color: white !important;
  display: flex;
  align-items: center;
  justify-content: space-between; // Asegura que los elementos estén espaciados
`;

const Logo = styled.img`
  height: 40px;
`;

const StyledCard = styled(Card)`
  margin-bottom: 20px;
  padding-bottom: 20px;
  padding-top: 10px;
  border-radius: 10px; // Añadir bordes redondeados
  box-shadow: 0 2px 8px rgba(0,0,0,0.1); // Añadir sombra para un efecto más estético
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledCardSearch = styled(Card)`
  margin-bottom: 20px;
  padding-bottom: 20px;
  background: linear-gradient(135deg, #2a0dd3 0%, #5433FF 100%);
  padding-top: 10px;
  border-radius: 10px; // Añadir bordes redondeados
  box-shadow: 0 2px 8px rgba(0,0,0,0.1); // Añadir sombra para un efecto más estético
  animation: ${fadeIn} 0.5s ease-in;
`;

const StyledAvatar = styled(Avatar)`
  background-color: #7265e6;
  margin-right: 10px;
`;

const StatusBadge = styled.span`
  background-color: #52c41a;
  color: white;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 12px;
`;

const TimelineIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${props => props.completed ? '#52c41a' : '#f0f0f0'};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const TimelineItem = styled(Timeline.Item)`
  .ant-timeline-item-head {
    background-color: transparent;
    border: none;
  }
  .ant-timeline-item-tail {
    left: 16px;
  }
  .ant-timeline-item-content {
    margin-left: 48px;
    min-height: 48px;
  }
`;

const ConsultarOtraButton = styled(Button)`
  color: white;
  border-color: white;
`;

const DateLabel = styled.span`
  position: absolute;
  left: -80px;
  width: 60px;
  text-align: right;
  font-size: 12px;
  color: #888;
`;

const TimelineWrapper = styled.div`
  padding-left: 20px;
`;

const FileLink = styled.a`
  display: block;
  margin-top: 8px;
  color: #1890ff;
`;

const ImagePreview = styled(Image)`
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 5px;
`;

const StyledFooter = styled(Footer)`
  text-align: center;
  padding: 24px;
`;


const KikertLink = styled.a`
  color: #1890ff;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledButton = styled(KButton)`
  border-radius: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
`;

const StyledInput = styled(Input)`
  transition: all 0.3s ease;
  &:hover, &:focus {
    box-shadow: 0 0 0 2px rgba(255,255,255,0.2);
  }
`;

const KikertLogo = styled.img`
  width: 80px;
  height: auto;
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
`;

const ServiceDetailPublic = () => {
  const { id } = useParams();
  const [serviceData, setServiceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [folio, setFolio] = useState(id);
  const [checkOrder, setCheckOrder] = useState(false);
  const [orderCode, setOrderCode] = useState('');

  const history = useHistory();

  useEffect(() => {
    if (id && id!=='check') {
      fetchServiceData(id);
    }else if(id==='check'){
      setCheckOrder(true)
      setLoading(false)
    }
  }, [id]);


  const fetchServiceData = async (folioNumber) => {
    setLoading(true);
    try {
      const response = await axiosApi.get(`/service/mobile/order/?folio=${folioNumber}`);
      setServiceData(response.data);
      history.push(`/client/public/service-detail/${folioNumber}`);
      setCheckOrder(false)
      setLoading(false);
    } catch (error) {
      message.error('No se encontró el folio');
      setLoading(false);
    }
  };

  const handleOrderSearch = () => {
    if (orderCode) {
      fetchServiceData(orderCode);
    } else {
      message.warning('Por favor ingrese un código de orden válido');
    }
  };



  const isImageFile = (contentType) => {
    return contentType.startsWith('image/');
  };

  if (loading) {
    return (
      <Spin
        tip="Cargando..."
        size="large"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1000,
        }}
      >
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 999,
        }} />
      </Spin>
    );
  }

  const TitleSection=({title})=>{
    return(
      <Title level={4} style={{ marginBottom: 0,paddingBottom:0, borderBottom: "solid 1px #341eff", marginBottom:10 }}>
        <img
            src={turn}
            alt="logo"
            style={{ height: 32, width: 18, transform: "scaleX(-1)" }}
        />{" "}
        {title}
      </Title>
    )
  } 

  return (
    <StyledLayout>
      <StyledHeader>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {
            serviceData?.branch?.logo && <Logo src={serviceData?.branch?.logo} alt="Logo" />
          }
          <Title level={4} style={{ margin: 0, marginLeft: 20, color: 'white' }}>Consulta express</Title>
        </div>
        <ConsultarOtraButton type="ghost" onClick={() => {
          setCheckOrder(true);
          setServiceData(null);
          history.push('/client/public/service-detail/check');
        }}>
          Consultar otra orden
        </ConsultarOtraButton>
      </StyledHeader>
      <Content style={{ padding: '20px' }}>
        {
            checkOrder && <div>
            <Row justify="center">
            <Col xs={24} md={12}>
              <StyledCardSearch>
                
                <Title level={3} style={{color: "white"}}>Consulta el estado de tu orden</Title>
                <StyledInput
                  prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  className="kinput"
                  allowClear
                  placeholder="Ingresa el código de tu orden"
                  onChange={(e) => setOrderCode(e.target.value)}
                  style={{ marginBottom: '10px' }}
                />
                <StyledButton type="secondary" text="Buscar orden" icon={<SearchOutlined />} onClick={handleOrderSearch} block/>
                <a href="https://kikert.com" style={{color: "white"}}>Powered by <KikertLogo src={'/logo_white.svg'} alt="Kikert logo" /></a>
              </StyledCardSearch>
              </Col>
            </Row>
          </div>
        }
        {serviceData && (
          <Row gutter={[16, 16]} justify="center">
            <Col xs={24} md={8}>
              <StyledCard>
                <TitleSection title="Asesor"/>
                <Row align="middle">
                  <Col>
                    <StyledAvatar size={64} src={serviceData.assigned_to.userprofile.image_profile}/>
                  </Col>
                  <Col style={{ marginLeft: 10 }}>
                    <Text strong>{`${serviceData.assigned_to.first_name} ${serviceData.assigned_to.last_name}`}</Text>
                    <br/>
                    <Text><WhatsAppOutlined  style={{color: "#25d366"}}/> {serviceData?.assigned_to?.userprofile?.phone_number ? serviceData?.assigned_to?.userprofile?.phone_number : "No disponible"}</Text>
                  </Col>
                </Row>
                <br />
                <Text strong>Num. Orden: </Text>
                <Text>{serviceData.order_number}</Text>
                {serviceData?.current_location?.name && <><br />
                <Text strong>Locación: </Text>
                <Text>{serviceData?.current_location?.name}</Text></>}
              </StyledCard>
              <StyledCard>
              <TitleSection title="Unidad"/>
                <Text strong>Cliente: </Text>
                <Text>{`${serviceData.customer.first_name} ${serviceData.customer.last_name}`}</Text>
                <br />
                <Text strong>Fecha promesa de entrega: </Text>
                <Text>{serviceData.delivery_promise_date ? moment(serviceData.delivery_promise_date).format('DD/MM/YYYY') : 'No definida'}</Text>
                <br />
                <Text strong>Tipo: </Text>
                <Text>{serviceData.order_subtype}</Text>
                <br />
                <Text strong>Auto: </Text>
                <Text>{`${serviceData.WORKSHOP.vehicle.model.brand.name} ${serviceData.WORKSHOP.vehicle.model.name}`}</Text>
                <br />
                <Text strong>VIN: </Text>
                <Text>{serviceData.WORKSHOP.vehicle.vin}</Text>
                <br />
                <Text strong>Placas: </Text>
                <Text>{serviceData.WORKSHOP.vehicle.license_plate}</Text>
              </StyledCard>
            </Col>
            <Col xs={24} md={16}>
              {serviceData.workflowsteplog_set && serviceData.workflowsteplog_set.length > 0 ? (
                <TimelineWrapper>
                  <Timeline>
                    {serviceData.workflowsteplog_set
                    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                    .map((step, index) => (
                      <TimelineItem
                        key={index}
                        dot={
                          <TimelineIcon completed={step.status === 'STOP'} style={{ marginTop: '40px' }}>
                            {step.status === 'STOP' ? (
                              <CheckCircleFilled style={{ fontSize: '20px' }} />
                            ) : (
                              <ClockCircleOutlined style={{ fontSize: '20px', color: '#1890ff' }} />
                            )}
                          </TimelineIcon>
                        }
                      >
                        <DateLabel>{moment(step.timestamp).format('MMM DD').toUpperCase()}</DateLabel>
                        <StyledCard>
                          <Text strong style={{ color: '#1711bd', fontSize: '16px' }}>{step.step.name}</Text>
                          <br />
                          <Text>{step?.assigned_to?.first_name} {step?.assigned_to?.last_name}</Text>
                          <br />
                          <StatusBadge style={{ backgroundColor: step.status === 'STOP' ? '#52c41a' : '#1890ff' }}>
                            {step.status === 'STOP' ? 'FINALIZADO' : 'EN PROCESO'}
                          </StatusBadge>
                          {step.files && step.files.length > 0 && (
                            <div style={{ marginTop: '10px' }}>
                              <Text strong>Archivos adjuntos:</Text>
                              <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
                                <Image.PreviewGroup>
                                  {step.files.map((file, fileIndex) => (
                                    isImageFile(file.content_type) ? (
                                      <ImagePreview
                                        key={fileIndex}
                                        src={file.file}
                                        alt={file.name}
                                      />
                                    ) : (
                                      <FileLink key={fileIndex} href={file.file} target="_blank" rel="noopener noreferrer">
                                        <FileOutlined /> {file.name}
                                      </FileLink>
                                    )
                                  ))}
                                </Image.PreviewGroup>
                              </div>
                            </div>
                          )}
                        </StyledCard>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </TimelineWrapper>
              ) : (
                <h1 style={{ textAlign: 'center' }}>En recepción</h1>
              )}
            </Col>
          </Row>
        )}
      </Content>

      <StyledFooter>
        Powered by <KikertLink href="https://kikert.com" style={{ color: '#081a91' }} target="_blank" rel="noopener noreferrer">Kikert</KikertLink>
      </StyledFooter>
    </StyledLayout>
  );
};

export default ServiceDetailPublic;