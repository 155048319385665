import React, { useState, useEffect } from 'react'
import { useParams, Link, useHistory } from "react-router-dom";
import {
  Form,
  Avatar,
  Layout,
  Button,
  Space,
  Row,
  Col,
  Skeleton,
  Card,
  message,
  Typography,
  Image
} from 'antd'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UnorderedListOutlined,
  MessageOutlined,
  LinkOutlined
} from '@ant-design/icons';
import MenuServiceDetail from '../../../components/services/MenuServiceDetail'
import SectionTitle from '../../../components/common/elements/SectionTitle/SectionTitle'
import TagDescription from '../../../components/TagDescription'
import { UserOutlined, CalendarOutlined } from "@ant-design/icons";
import telefono_icon from '../../../image/detail/telefono_icon.svg'
import mensaje_icon from '../../../image/detail/mensaje_icon_1.svg'
import editar_icon from '../../../image/detail/editar_icon.svg'
import caratula_icon from '../../../image/detail/caratula_icon.svg'
import eliminarexpediente_icon from '../../../image/detail/eliminarexpediente_icon.svg'
import appstore from '../../../image/appstore.png'
import playstore from '../../../image/playstore.png'
import mensaje2_icon_1 from '../../../image/detail/mensaje2_icon_1.svg'
import archivos_icon from '../../../image/detail/archivos_icon.svg'
import perdidatotal_icon from '../../../image/detail/perdidatotal_icon.svg'
import QRCode from "react-qr-code";
import axiosApi, { tenantSelected } from "../../../lib/utils/axiosApi";
import { deleteServiceOrder, getServiceDetail, changePromiseDateOrder, changeAsesor } from '../../../redux/serviceOrderDuck'
import TabsOptions from "../../../components/dashboard/TabsOptions";
import _ from 'lodash'
import moment from 'moment';
import { Global, css } from "@emotion/react";
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from "react-intl";
import { changeLangAction } from '../../../redux/settingsDuck';
import "../../../components/sideBarDerecha2/SiderBarRight.css"
import ServiceLayout from '../../../layouts/ServiceLayout';
import KButton from "../../../components/common/elements/KButton/KButton";
import KModal from "../../../components/common/elements/KModal/KModal";
import locale from "antd/es/date-picker/locale/es_ES";
import KSelect from "../../../components/common/elements/KSelect/KSelect";
import FormChangePromiseDate from "../../../components/services/FormChangePromiseDate";
import { getUsersAdvisors } from "../../../redux/membersDuck";
import StatusOrder from "../../../components/services/StatusOrder";
import useSettingsKikert from "../../../hooks/useSettingsKikert";
import SelectLocations from "../../../components/Selects/SelectLocations";
import { subtypes } from "../../../lib/utils/utils";
import MainLayoutv2 from "../../../layouts/MainLayoutv2";
const { Header, Content, Footer } = Layout;
const { Text, Title } = Typography;


const ServiceDetail = ({ changeLangAction,
  intl,
  members,
  settings,
  getServiceDetail,
  changePromiseDateOrder,
  serviceOrder,
  deleteServiceOrder,
  getUsersAdvisors,
  changeAsesor,
  ...props }) => {

  let { id } = useParams();
  let history = useHistory();
  const [visibleModalDelete, setVisibleModalDelete] = useState(false)
  const [visibleModalPromise, setVisibleModalPromise] = useState(false)
  const [visibleAsesor, setVisibleAsesor] = useState(false)
  const [showModalLocation, setShowModalLocation] = useState(false)
  const [promiseDate, setPromiseDate] = useState(null)
  const [asesorSelected, setAsesorSelected] = useState(null)
  const [formAsesor] = Form.useForm();
  const { settingsKikert, getMetaValue } = useSettingsKikert()
  const HIDE_LICENSE_PLATE_INPUT = getMetaValue('HIDE_LICENSE_PLATE_INPUT');
  const ECONOMIC_NUMBER_LABEL = getMetaValue('ECONOMIC_NUMBER_LABEL');
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    if (id) {
      console.log("id", id);
      getServiceDetail(id);
      getUsersAdvisors()
    }
  }, [])

  useEffect(() => {
    console.log("serviceOrder", serviceOrder);
  }, [serviceOrder])



  const changeLocation = async (values) => {
    setLoading(true)
    try {
      const res = await axiosApi.post('/service/order/' + serviceOrder?.serviceDetail?.id + '/set_location/', values);
      message.success('Cambiado exitosamente')
      getServiceDetail(id);
      getUsersAdvisors()
    } catch (e) {
      message.error('Hubo un error, porfavor intenta nuevamente')
    } finally {
      setLoading(false)
      setShowModalLocation(false)
    }
  }

  const changeAsesorFinish = async (values) => {
    try {
      const res = await changeAsesor(id, values)
      if (res.success === true) {
        message.success(intl.formatMessage({ id: "update_success" }))
        await getServiceDetail(id)
      } else {
        message.error(intl.formatMessage({ id: "update_success" }))
      }
    } catch (e) {
      message.error(intl.formatMessage({ id: "update_success" }))
    } finally {
      setVisibleAsesor(false)
    }

  }




  const GroupButtons = () => {
    return (
      <div>
        <Button
          size={"middle"}
          block
          icon={
            <img
              src={mensaje2_icon_1}
              width={15}
              style={{ marginRight: 10 }}
            />
          }
          style={{ marginTop: 10 }}
          type={"primary"}
          className={"button-secondary"}
        >
          <FormattedMessage id="services.mess" />
        </Button>
        <Button
          size={"middle"}
          block
          icon={
            <img
              src={archivos_icon}
              width={8}
              style={{ marginRight: 10 }}
            />
          }
          style={{ marginTop: 10 }}
          type={"primary"}
          className={"btn-low-blue"}
        >
          <FormattedMessage id="sideBar.left.file" />
        </Button>
        <Button
          size={"middle"}
          block
          icon={
            <img
              src={perdidatotal_icon}
              width={15}
              style={{ marginRight: 10 }}
            />
          }
          style={{ marginTop: 10 }}
          type={"primary"}
          className={"button-primary"}
        >
          <FormattedMessage id="servicesDetails.moveToRepairQueue" />
        </Button>
        <Button
          size={"middle"}
          block
          icon={
            <img
              src={perdidatotal_icon}
              width={15}
              style={{ marginRight: 10 }}
            />
          }
          style={{ marginTop: 10 }}
          type={"primary"}
          className={"button-primary"}
        >
          <FormattedMessage id="servicesDetails.markAsTotalLoss" />
        </Button>
        <Button
          size={"middle"}
          block
          icon={
            <img
              src={eliminarexpediente_icon}
              width={15}
              style={{ marginRight: 10 }}
            />
          }
          style={{ marginTop: 10 }}
          type={"primary"}
          className={"button-primary"}
        >
          <FormattedMessage id="servicesDetails.deleteFiles" />{" "}
        </Button>
      </div>
    );
  }

  const deleteOrder = async () => {
    try {
      const res = await deleteServiceOrder(id)
      message.success(intl.formatMessage({ id: "item.deleteSuccess" }))
      history.push('/services/list')
    } catch (e) {
      console.log(e)
      message.error(intl.formatMessage({ id: "item.brand.registerErr" }))

    }
  }

  const onChangePromise = async (values) => {
    try {
      const res = await changePromiseDateOrder(id, {
        reason: values.reason,
        datetime: values.datetime
      })
      message.success(intl.formatMessage({ id: "update_success" }))
    } catch (e) {
      message.error(`${e}`)
    } finally {
      setVisibleModalPromise(false)
    }
  }


  const DescriptionSection = () => {
    return (
      <Row>
        <Col xs={24} md={12}>
          <Avatar size={80} style={{ backgroundColor: '#7265e6' }} src={_.get(serviceOrder, 'serviceDetail.assigned_to.userprofile.image_profile', null)}  >
            {_.get(serviceOrder, 'serviceDetail.assigned_to.first_name', null) && serviceOrder.serviceDetail.assigned_to.first_name[0]}
          </Avatar>
          <TagDescription
            title={'Fecha Recepción'}
            width={70}
            value={
              serviceOrder.serviceDetail
                ? moment(serviceOrder.serviceDetail.created_at).format(
                  "DD/MM/YYYY"
                )
                : "--"
            }
          />
          <TagDescription
            title={'Fecha Creación'}
            width={70}
            value={
              serviceOrder.serviceDetail
                ? moment(serviceOrder.serviceDetail.timestamp).format(
                  "DD/MM/YYYY"
                )
                : "--"
            }
          />
          <TagDescription
            title={intl.formatMessage({ id: "services.page.advisor" })}
            width={70}
            value={`${_.get(
              serviceOrder,
              "serviceDetail.assigned_to.first_name",
              ""
            )} ${_.get(
              serviceOrder,
              "serviceDetail.assigned_to.last_name"
            )}`}
          />
          {
            _.get(
              serviceOrder,
              "serviceDetail.is_invoiced"
            ) &&

            <>
              <TagDescription
                title={'Facturado'}
                width={70}
                value={`
                            
                           ${_.get(
                  serviceOrder,
                  "serviceDetail.invoice_reference"
                ) ? `Referencia - ${_.get(
                  serviceOrder,
                  "serviceDetail.invoice_reference"
                )} ` : 'Si'}`}
              />
            </>
          }
          <TagDescription
            icon={mensaje_icon}
            value={_.get(
              serviceOrder,
              "serviceDetail.assigned_to.email",
              "--"
            )}
          />
          {
            serviceOrder.serviceDetail?.status !== 'CLOSED' &&
            <KButton
              size="small"
              onClick={() => setVisibleAsesor(true)}
              type={'third'}
              text={<FormattedMessage id="servicesDetails.changeAdvisor" />}
              style={{ fontSize: 10, padding: "0 20px", margin: "5px 0px" }}
            />
          }
        </Col>
        <Col xs={24} md={12}>
          <TagDescription
            title={"No. Orden"}
            width={70}
            value={_.get(serviceOrder, "serviceDetail.order_number", "--")}
          />

          {
            getMetaValue('HIDE_PYRAMID_FIELD') !== "1" && (
              <TagDescription
                title={intl.formatMessage({ id: "services.createNew.pyramid" })}
                width={70}
                value={_.get(
                  serviceOrder,
                  "serviceDetail.order_dynamic_data.pyramid",
                  "--"
                )}
              />
            )
          }


          <TagDescription
            width={70}
            title={intl.formatMessage({ id: "servicesDetails.deliveryPromise" })}
            value={_.get(serviceOrder, 'serviceDetail.delivery_promise_date', null)
              ? `${moment(serviceOrder.serviceDetail?.delivery_promise_date).format('DD/MM/YYYY, h:mmA')}` : 'N/A'}
          />

          {
            _.get(serviceOrder, 'serviceDetail.closing_datetime', null) &&
            <TagDescription
              width={70}
              title={intl.formatMessage({ id: "saleOrder.deliveryDateClose" })}
              value={_.get(serviceOrder, 'serviceDetail.closing_datetime', null)
                ? `${moment(serviceOrder.serviceDetail?.closing_datetime).format('DD/MM/YYYY, h:mmA')}` : 'N/A'}
            />
          }
          {
            serviceOrder.serviceDetail?.status !== 'CLOSED' &&
            <KButton
              size={'small'}
              style={{ fontSize: 10, padding: "0 20px", margin: "5px 0px" }}
              onClick={() => setVisibleModalPromise(true)}
              type={"primary"}
              text={<FormattedMessage id="servicesDetails.changePromise" />}
              className={"btn-low-blue"}
            />
          }

          <h3><b>Observaciones:</b></h3>
          <p style={{ paddingRight: 10 }}>{_.get(
            serviceOrder,
            "serviceDetail.comment",
            "N/A"
          )}</p>

          {
            serviceOrder.serviceDetail?.WORKSHOP?.metadata?.order_type &&
            <>
              <h3><b>Tipo:</b></h3>
              <p style={{ paddingRight: 10 }}>{serviceOrder.serviceDetail?.WORKSHOP?.metadata?.order_type ? serviceOrder.serviceDetail?.WORKSHOP?.metadata?.order_type : 'N/A'}</p>

            </>
          }

          {
            serviceOrder.serviceDetail?.WORKSHOP?.metadata?.order_version &&
            <>
              <h3><b>Versión:</b></h3>
              <p style={{ paddingRight: 10 }}>{serviceOrder.serviceDetail?.WORKSHOP?.metadata?.order_version ? serviceOrder.serviceDetail?.WORKSHOP?.metadata?.order_version : 'N/A'}</p>

            </>
          }


        </Col>


        <KModal
          title={<FormattedMessage id="servicesDetails.changeAdvisor" />}
          onCancel={() => setVisibleAsesor(false)}
          visible={visibleAsesor}>
          <Col span={24}>
            <Form
              initialValues={{ user_id: serviceOrder?.serviceDetail?.assigned_to.id }}
              onFinish={changeAsesorFinish}
              layout="vertical"
            >
              <Space direction={'vertical'} style={{ width: '100%' }}>
                <Form.Item
                  label={<FormattedMessage id="services.page.advisor" />}
                  name="user_id"
                >
                  <KSelect
                    options={
                      members?.usersAdvisorsList ? _.map(members?.usersAdvisorsList, (o) => {
                        return {
                          value: o.id,
                          label: `${o.first_name} ${o.last_name}`
                        }
                      }) : []
                    }
                  />
                </Form.Item>
                <div style={{ textAlign: 'center' }}>
                  <KButton type={'secondary'} onClick={() => setVisibleAsesor(false)} text={intl.formatMessage({ id: "cancel" })} />
                  <KButton htmlType={'submit'} text={intl.formatMessage({ id: "accept" })} />
                </div>
              </Space>
            </Form>

          </Col>
        </KModal>


        <KModal
          title={intl.formatMessage({ id: "servicesDetails.changeDatePromise" })}
          onCancel={() => setVisibleModalPromise(false)}
          loading={serviceOrder.loading}
          visible={visibleModalPromise}>
          <Col span={24}>
            <FormChangePromiseDate onCancel={() => {
              getServiceDetail(id ? id : 0)
              setVisibleModalPromise(false)
            }} orderId={id ? id : 0}
              currentDatePromise={_.get(serviceOrder, 'serviceDetail.delivery_promise_date', undefined)}
              onSubmit={onChangePromise} />
          </Col>
        </KModal>
      </Row>
    );
  }

  const getLabelFromSubtype = (CODE_SUBTYPE) => {
    let subtype = subtypes.find((ele) => ele.value === CODE_SUBTYPE);
    if (subtype) {
      return ` - ${subtype.label}`;
    }
    return ''
  }

  const copyUrlToClipboard = () => {
    const folio = serviceOrder?.serviceDetail?.folio;
    const url = `${window.location.origin}/client/public/service-detail/${folio}`;
    navigator.clipboard.writeText(url)
      .then(() => {
        message.success('Link copiado al portapapeles');
      })
      .catch(() => {
        message.error('Error al copiar la URL');
      });
  };

  return (
    <>
      <Global
        styles={css`
            .itemDescripcion > .ant-form-item-label > label {
              font-weight: normal !important;
            }
          `}
      />
      <MainLayoutv2 isServiceDetail={true} title={intl.formatMessage({ id: "servicesDetails.servicesDetails" })} index="expedientes">
        <Layout style={{ marginTop: 10 }}>
          {/*<TabsOptions />*/}
          <Card className="card_details_service">

            <MenuServiceDetail menuSelected={0} />
            <Row justify="end" style={{ marginTop: 10 }}>
              {
                serviceOrder?.serviceDetail?.status !== 'CLOSED' && <Col>
                  <Space size="small">
                    <Link to={`/surveys/?order_id=${id}`}>
                      <KButton
                        icon={<UnorderedListOutlined />}
                        size={"large"}
                        text={"Iniciar encuesta"}
                        type={"primary"}
                      />
                    </Link>

                    <Link to={`/services/${id}`}>
                      <KButton
                        size={"large"}
                        text={<FormattedMessage id="layout.edit" />}
                        icon={
                          <img
                            src={editar_icon}
                            width={15}
                            style={{ marginRight: 10, marginTop: "-5px" }}
                          />
                        }
                        type={"primary"}
                      />
                    </Link>
                  </Space>
                </Col>
              }

            </Row>
            <SectionTitle title={intl.formatMessage({ id: "servicesDetails.service" }) + `${getLabelFromSubtype(serviceOrder?.serviceDetail?.order_subtype)}`} />
            <Row style={{ padding: 10 }}>
              <Col xs={24} md={12}>
                <Skeleton active loading={serviceOrder.loading}>
                  <DescriptionSection />
                </Skeleton>
              </Col>
              <Col xs={24} md={12}>
                <Skeleton active loading={serviceOrder.loading}>

                  <Row>
                    <Col md={12} sm={12} xs={24}>
                      <QRCode size={150}
                        value={`{ "Escanea": "desde la app kikert", "tenant": "${tenantSelected}", "folio": "${serviceOrder?.serviceDetail?.folio}", "order_id": ${serviceOrder?.serviceDetail?.id}}`} />
                      <h3>Escanéa este QR desde nuestra aplicación móvil</h3>
                      <Space>
                        <a href={'https://play.google.com/store/apps/details?id=com.vwnorte.kikert&hl=es_MX'} target={'_blank'}>
                          <Image style={{ width: 150 }} preview={false} src={playstore} />
                        </a>
                        <a href={'https://apps.apple.com/mx/app/kikert/id1630557136'} target={'_blank'}>
                          <Image style={{ width: 140 }} preview={false} src={appstore} />
                        </a>
                      </Space>
                    </Col>
                    <Col md={12} sm={12} xs={24}>
                      <Space>
                        <KButton
                          icon={<LinkOutlined />}
                          size={"large"}
                          type={'secondary'}
                          text={"Compartir link del servicio"}
                          onClick={copyUrlToClipboard}
                        />
                      </Space>
                    </Col>
                  </Row>



                </Skeleton>
                {/* {serviceOrder.loading ? null : (
                  <Form
                    layout={"vertical"}
                    name="nest-messages"
                    onFinish={onFinish}
                  >
                    <Form.Item name={"observaciones"} label="Observaciones">
                      <Input.TextArea
                        readOnly
                        rows={4}
                        className={"textarea-gray"}
                      />
                    </Form.Item>
                  </Form>
                )} */}
              </Col>
            </Row>
            <Row gutter={14}>
              <Col md={12} xs={24}>
                <SectionTitle title={intl.formatMessage({ id: "services.createNew.uniUnitt" })} />
                <Row style={{ margin: 20 }}>
                  <Skeleton active loading={serviceOrder.loading}>
                    <Col xs={24} md={24}>
                      <Avatar style={{ backgroundColor: '#2a16e0' }} size={80}  >
                        {
                          _.get(serviceOrder, 'serviceDetail.WORKSHOP.vehicle.model.brand.name', null) &&
                          serviceOrder.serviceDetail?.WORKSHOP?.vehicle?.model?.brand?.name
                        }
                      </Avatar>
                    </Col>
                    <Col xs={24} md={24}>
                      <TagDescription
                        title={intl.formatMessage({ id: "services.createNew.unit" })}
                        value={
                          (_.get(serviceOrder, 'serviceDetail.WORKSHOP.vehicle.model.brand.name', null) ?
                            serviceOrder.serviceDetail?.WORKSHOP?.vehicle?.model?.brand?.name : '') + ' ' +
                          (_.get(serviceOrder, 'serviceDetail.WORKSHOP.vehicle.model.name', null) ?
                            serviceOrder.serviceDetail?.WORKSHOP?.vehicle?.model?.name : '')
                        }
                        width={100}
                      />
                      <TagDescription
                        title={'Año'}
                        value={
                          (_.get(serviceOrder, 'serviceDetail.WORKSHOP.vehicle.year', null) ?
                            serviceOrder.serviceDetail?.WORKSHOP?.vehicle?.year : '--')
                        }
                        width={100}
                      />

                      {
                        serviceOrder.serviceDetail?.WORKSHOP?.metadata?.metaLevel &&
                        <TagDescription
                          title={'Nivel'}
                          value={
                            serviceOrder.serviceDetail?.WORKSHOP?.metadata?.metaLevel ? serviceOrder.serviceDetail?.WORKSHOP?.metadata?.metaLevel.split('_')[0] : '--'
                          }
                          width={100}
                        />
                      }

                      <TagDescription
                        title={'VIN'}
                        value={
                          (_.get(serviceOrder, 'serviceDetail.WORKSHOP.vehicle.vin', null) ?
                            serviceOrder.serviceDetail?.WORKSHOP?.vehicle?.vin : '--')
                        }
                        width={100}
                      />
                      <TagDescription
                        title={'Color'}
                        value={
                          (_.get(serviceOrder, 'serviceDetail.WORKSHOP.vehicle.color.name', null) ?
                            serviceOrder.serviceDetail?.WORKSHOP?.vehicle.color?.name : '--')
                        }
                        width={100}
                      />

                      {
                        HIDE_LICENSE_PLATE_INPUT === '0' && <TagDescription
                          title={intl.formatMessage({ id: "services.createNew.plates" })}
                          value={serviceOrder?.serviceDetail?.order_dynamic_data?.vehicle?.license_plate || 'N/A'}
                          width={100}
                        />
                      }





                      {
                        tenantSelected !== 'jacmexico' &&
                        <TagDescription
                          title={`${ECONOMIC_NUMBER_LABEL ? ECONOMIC_NUMBER_LABEL : 'Núm. económico'}`}
                          value={serviceOrder?.serviceDetail?.WORKSHOP?.vehicle?.economic_number || 'N/A'}
                          width={100}
                        />
                      }

                      <TagDescription
                        title={`Transmisión`}
                        value={serviceOrder?.serviceDetail?.WORKSHOP?.vehicle?.transmission === 'AUTOMATIC' ? 'Automática' : serviceOrder?.serviceDetail?.WORKSHOP?.vehicle?.transmission === 'STANDARD' ? 'Estandar' : 'N/A'}
                        width={100}
                      />


                      {
                        serviceOrder?.serviceDetail?.current_location && <> <TagDescription
                          title={"Locación"}
                          value={serviceOrder?.serviceDetail?.current_location?.name || 'N/A'}
                          width={100}
                        />
                        </>
                      }
                      <KButton size={'small'} onClick={() => setShowModalLocation(true)} text={'Cambiar locación'} />



                      {/* <TagDescription
                            title={intl.formatMessage({ id: "services.saleOrder.year" })}
                            value={
                                (_.get(serviceOrder, 'serviceDetail.WORKSHOP.vehicle.year', null) ?
                                    serviceOrder.serviceDetail?.WORKSHOP?.vehicle.year : '--')
                            }
                            width={100}
                        /> */}
                    </Col>
                  </Skeleton>
                </Row>
              </Col>
              <Col md={12} xs={24}>
                <SectionTitle title={intl.formatMessage({ id: "services.page.costumer" })} />
                <Row style={{ margin: 20 }}>
                  <Col xs={24}>

                    <Skeleton active loading={serviceOrder.loading}>

                      <TagDescription
                        title={intl.formatMessage({ id: "layout.name" })}
                        value={`${_.get(
                          serviceOrder,
                          "serviceDetail.customer.first_name",
                          ""
                        )} ${_.get(
                          serviceOrder,
                          "serviceDetail.customer.last_name"
                        )}`}
                        width={70}
                      />
                      <TagDescription
                        title={intl.formatMessage({ id: "services.createNew.address" })}
                        value={
                          `${serviceOrder?.serviceDetail?.customer?.address ? serviceOrder?.serviceDetail?.customer?.address : ''} 
                          ${serviceOrder?.serviceDetail?.customer?.int_num ? serviceOrder?.serviceDetail?.customer?.int_num : ''}
                          ${serviceOrder?.serviceDetail?.customer?.ext_num ? serviceOrder?.serviceDetail?.customer?.ext_num : ''}
                          ${serviceOrder?.serviceDetail?.customer?.colony ? serviceOrder?.serviceDetail?.customer?.colony : ''}
                          ${serviceOrder?.serviceDetail?.customer?.zip_code ? serviceOrder?.serviceDetail?.customer?.zip_code : ''}
                          ${serviceOrder?.serviceDetail?.customer?.municipality ? serviceOrder?.serviceDetail?.customer?.municipality : ''}
                          ${serviceOrder?.serviceDetail?.customer?.state ? serviceOrder?.serviceDetail?.customer?.state : ''}
                          `
                        }
                        width={70}
                      />
                      <TagDescription
                        title={intl.formatMessage({ id: "servicesDetails.company" })}
                        value={
                          serviceOrder.serviceDetail?.customer?.company_name &&
                            serviceOrder.serviceDetail?.customer?.company_name
                            ? serviceOrder.serviceDetail?.customer?.company_name
                            : "N/A"
                        }
                        width={70}
                      />
                      <TagDescription
                        title={'Tipo de persona'}
                        value={`${_.get(
                          serviceOrder,
                          "serviceDetail.customer.person_type",
                          "PF"
                        ) === 'PM' ? 'Persona moral' : 'Persona física'}`}
                        width={70}
                      />

                      <TagDescription
                        title={'RFC'}
                        value={serviceOrder?.serviceDetail?.customer?.rfc || '--'}
                        // value={`${_.get(serviceOrder, "serviceDetail.customer.rfc"),'--'}`} 
                        width={70}
                      />

                      {

                        !serviceOrder.serviceDetail?.closing_datetime &&
                        <KButton
                          icon={<MessageOutlined style={{ marginRight: 8 }} />}
                          text={<FormattedMessage id="chat.chatCustomer" />}
                          type={'secondary'}
                          onClick={() => history.push(`/order-chat/${serviceOrder.serviceDetail.id}`)}
                          style={{
                            fontSize: 13,
                            padding: "0 20px",
                            margin: "5px 0px",
                          }}
                        />
                      }

                      <TagDescription
                        icon={telefono_icon}
                        value={_.get(
                          serviceOrder,
                          "serviceDetail.customer.phone_number"
                        )}
                      />
                      <TagDescription
                        icon={mensaje_icon}
                        value={_.get(
                          serviceOrder,
                          "serviceDetail.customer.email"
                        )}
                      />
                    </Skeleton>
                  </Col>
                </Row>
                <Row justify={'end'}>
                  <Col>{serviceOrder.loading ? null :
                    <KButton
                      size={"normal"}
                      text={<FormattedMessage id="servicesDetails.deleteExpedient" />}
                      type={'link'}
                      onClick={() => setVisibleModalDelete(true)}
                      style={{ marginTop: 10, color: 'red', float: 'right' }}
                    />

                  }</Col>
                </Row>
              </Col>
            </Row>
            {
              settings && settings.insuranceIsActive && serviceOrder?.serviceDetail?.order_dynamic_data?.insurance_type === 'I' && (
                <Row gutter={14}>
                  <Col md={12} xs={24}>
                    <SectionTitle title={intl.formatMessage({ id: "services.insure" })} />
                    <Row style={{ margin: 20 }}>
                      <Skeleton active loading={serviceOrder.loading}>
                        <Col xs={24} md={5}>
                          <Avatar style={{ backgroundColor: '#7265e6' }} src={_.get(serviceOrder, 'serviceDetail.order_dynamic_data.insurance.logo', null)} size={80}  >
                            {
                              _.get(serviceOrder, 'serviceDetail.order_dynamic_data.insurance.name', null) &&
                              serviceOrder.serviceDetail.order_dynamic_data.insurance.name[0]
                            }
                          </Avatar>
                        </Col>
                        <Col xs={24} md={16}>
                          <TagDescription
                            title={intl.formatMessage({ id: "servicesDetails.company" })}
                            value={
                              serviceOrder.serviceDetail &&
                                serviceOrder.serviceDetail?.order_dynamic_data?.insurance?.name
                                ? serviceOrder?.serviceDetail?.order_dynamic_data?.insurance
                                  ?.name
                                : "--"
                            }
                            width={100}
                          />
                          <TagDescription
                            title={intl.formatMessage({ id: "services.createNew.adjuster" })}
                            value={
                              serviceOrder.serviceDetail &&
                                serviceOrder.serviceDetail?.order_dynamic_data?.insurance_agent
                                  ?.name
                                ? serviceOrder.serviceDetail.order_dynamic_data
                                  ?.insurance_agent?.name
                                : "--"
                            }
                            width={100}
                          />
                          <TagDescription
                            title={'¿Aplica deducible?'}
                            value={serviceOrder?.serviceDetail?.WORKSHOP?.with_deductible ? <CheckCircleOutlined style={{ color: 'green' }} /> : <CloseCircleOutlined style={{ color: 'red' }} />}
                            width={100}
                          />
                          <TagDescription
                            title={intl.formatMessage({ id: "servicesDetails.deductible" })}
                            value={serviceOrder?.serviceDetail?.WORKSHOP?.valuation?.deductible_amount ? serviceOrder?.serviceDetail?.WORKSHOP?.valuation?.deductible_amount : '--'}
                            width={100}
                          />
                          <TagDescription
                            title={intl.formatMessage({ id: "servicesDetails.demerit" })}
                            value={serviceOrder?.serviceDetail?.WORKSHOP?.valuation?.demerit_amount ? serviceOrder?.serviceDetail?.WORKSHOP?.valuation?.demerit_amount : '--'}
                            width={100}
                          />
                          <TagDescription
                            title={intl.formatMessage({ id: "servicesDetails.policy" })}
                            value={_.get(serviceOrder, 'serviceDetail.WORKSHOP.policy_number', null) ? _.get(serviceOrder, 'serviceDetail.WORKSHOP.policy_number', null) : '--'}
                            width={100}
                          />
                          <TagDescription
                            title={intl.formatMessage({ id: "services.createNew.siniestroNumber" })}
                            value={
                              serviceOrder.serviceDetail &&
                                serviceOrder.serviceDetail.order_dynamic_data?.sinister_number
                                ? serviceOrder.serviceDetail.order_dynamic_data
                                  ?.sinister_number
                                : "--"
                            }
                            width={100}
                          />
                          {/*<TagDescription*/}
                          {/*    title={intl.formatMessage({ id: "servicesDetails.demerit" })}*/}
                          {/*    value={"2312321"}*/}
                          {/*    width={100}*/}
                          {/*/>*/}
                        </Col>
                      </Skeleton>
                    </Row>
                  </Col>
                </Row>
              )
            }

          </Card>
        </Layout>

        <KModal
          title={'Cambiar locación'}
          onCancel={() => setShowModalLocation(false)}
          loading={loading}
          visible={showModalLocation}
        >
          <Form
            onFinish={changeLocation}
          >
            <SelectLocations name={'location_id'} />
            <Form.Item>
              <KButton type={'secondary'} onClick={() => setShowModalLocation(false)} text={'Cancelar'} />
              <KButton type={'primary'} htmlType={'submit'} text={'Enviar'} />

            </Form.Item>
          </Form>

        </KModal>

        <KModal
          title={intl.formatMessage({ id: "servicesDetails.confirmDelete" })}
          onCancel={() => setVisibleModalDelete(false)}
          loading={serviceOrder.deleting}
          visible={visibleModalDelete}
        >
          <Col span={24} style={{ textAlign: "center" }}>
            <Text style={{ fontSize: 18 }}>{intl.formatMessage({ id: "servicesDetails.actionNotReversible" })}</Text>
          </Col>
          <Col span={24} style={{ textAlign: "center", marginTop: 40 }}>
            <Space>
              <KButton
                size="large"
                type={'secondary'}
                text={<FormattedMessage id="cancel" />}
                className="btn-string-blue btn-report-action"
                onClick={() => setVisibleModalDelete(false)}
              />
              <KButton
                size="large"
                type={'primary'}
                text={<FormattedMessage id="delete" />}
                className="btn-string-blue btn-report-action"
                onClick={() => deleteOrder()}
              />
            </Space>
          </Col>


        </KModal>

        {/* <Content style={{ padding: '10px 10px', background:'white', marginTop:8 }}>
        </Content> */}
      </MainLayoutv2>
    </>
  );
}


const mapStateToProps = state => ({
  settings: state.settings,
  serviceOrder: state.serviceOrder,
  members: state.members
})

export default injectIntl(connect(mapStateToProps, { deleteServiceOrder, changeLangAction, getServiceDetail, getUsersAdvisors, changePromiseDateOrder, changeAsesor })(ServiceDetail))
