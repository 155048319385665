import React, { useState, useEffect } from 'react'
import { axiosApi, API, tenantSelected, typeHttp } from '../../lib/utils/axiosApi'
import moment from 'moment'
import 'moment/locale/es'
import {
  FileExcelOutlined,
  PlusCircleOutlined,
  ControlOutlined,
  MailOutlined,
  PaperClipOutlined,
  ClipboardOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  FilterOutlined,
  SaveOutlined
} from "@ant-design/icons";
import { setModalFilter } from "../../redux/servicesDuck";
import { connect } from 'react-redux'
import DashboardLayout from "../../layouts/DashboardLayout";
import { injectIntl, FormattedMessage } from "react-intl";
import { changeLangAction } from '../../redux/settingsDuck';
import FilterServicesModal from "../../components/modal/FilterServicesModal";
import { CustomTable } from "../../components/KComponents";
import _ from 'lodash'
import { subtypes } from "../../lib/utils/utils";
import { Layout, Form, Input, Tag, Space, Row, Col, Checkbox, Menu, Tooltip, message, Popover, Spin } from 'antd'
import {
  useHistory,
  useParams,
  useLocation
} from "react-router-dom";
import MainLayoutv2 from "../../layouts/MainLayoutv2";
import { downloadFile } from "../../lib/utils/utils";
import KButton from "../../components/common/elements/KButton/KButton";
import NumberFormat from "../../components/common/NumberFormat";
import CreateFilterViewModal from "../../components/modal/CreateFilterViewModal";


const { Header, Content, Footer } = Layout;
const { Search } = Input;
const VIEWCODE = 'repair-list'; // codigo de los custom filters




const ServicePage = ({ changeLangAction, intl, settings, setModalFilter, ...props }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingViews, setLoadingViews] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [loadingJACBitacora, setLoadingJACBitacora] = useState(false);
  const [showModalFilterView, setShowModalFilterView] = useState(false);
  const [currentFilter, setCurrentFilter] = useState('');
  const [currentFSearch, setCurrentSearch] = useState(null);
  const [customViewsList, setCustomViewsList] = useState([]);
  const [count, setCount] = useState(null);
  const [hovered, setHovered] = useState(false);
  let { search } = useLocation();
  const [pagination, setPagination] = useState({
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
  });

  const history = useHistory();
  moment.lang("es");
  const [active, setActive] = useState(null);

  const getSubtypeLabel = (code) => {
    let subtype = subtypes.find((e) => e.value === code)
    return subtype ? subtype.label : code
  }
  useEffect(() => {
    getCustomViews()
  }, []);

  const getCustomViews = async () => {
    try {
      setLoadingViews(true)
      const res = await axiosApi.get(API.BUSINESS.CUSTOM_FILTER + `?for_me=true&view_code=${VIEWCODE}`);
      setCustomViewsList(res.data.results);
    } catch (e) {
      setCustomViewsList([])
    } finally {
      setLoadingViews(false)
    }
  }

  useEffect(() => {
    if (search) {
      search = '&' + search.replace('?filters=', '')
    }
    setCurrentFilter('&' + search)
    getServices(pagination.current, pagination.pageSize, search);
  }, [search])

  const useFilters = () => {
    const [trueFilter, setTrueFilter] = useState(false);

    const changeTrueFilter = () => setTrueFilter(!trueFilter);

    return {
      trueFilter,
      changeTrueFilter
    }
  }

  const FilterFolio = useFilters();
  const FilterMarca = useFilters();
  const FilterModelo = useFilters();
  const FilterAsesor = useFilters();
  const FilterCliente = useFilters();
  const FilterFAlta = useFilters();
  const FilterStatus = useFilters();
  const FilterAction = useFilters();

  const OpenCloseFilter = useFilters();

  const columns = [
    {
      title: "Folio",
      key: "folio",
      width: 100,
      render: (text, record) => (
        <>
          <a
            className={'text-secondary'}
            style={{ textDecoration: 'underline' }}
            onClick={() => {
              history.push(`/service-detail/${record.id}`);
            }}
          >
            {record.folio}
          </a>
          <p>
            {
              record.status === "OPEN" && <Tag color={'#108ee9'}>Abierto</Tag>
            }
            {
              record.status === "CLOSED" && <Tag color={'#87d068'}>Finalizado: {record.closing_reason?.name}</Tag>
            }
            {
              record.status === "RE-OPENED" && <Tag color={'#108ee9'}>Re abierto: {record.reopen_reason?.name}</Tag>
            }
            {
              record.is_deleted && <p><Tag color={'#ff0000'}>Eliminado</Tag></p>
            }
          </p>
          <Tooltip title="Comentarios">
            <a
                  onClick={() => {
                    history.push(`/service-messages/${record.id}`);
                  }}
                  style={{ color: !record?.comment_counter && 'gray' }}
                >
                <MailOutlined /> {record?.comment_counter ? record?.comment_counter : 0}
            </a>
          </Tooltip>
          <Tooltip title="Archivos">
            <a
                  onClick={() => {
                    history.push(`/service-files/${record.id}`);
                  }}
                  style={{ color: !record?.num_files && 'gray', marginLeft: 10 }}
                >
                  <PaperClipOutlined /> {record?.num_files ? record?.num_files : 0}
            </a>
          </Tooltip>
        </>
      ),
    },
    {
      title: "Descripción",
      key: "description",
      render: (text, record) => (
        <div style={{width: 200}}>
          <p style={{ marginBottom: 0 }}><b>{'Asesor: '}</b> {`${record.assigned_to.first_name} ${record.assigned_to.last_name}`}</p>
          <p style={{ marginBottom: 0 }}><b>{'Cliente: '}</b> {record.customer.first_name} {record.customer.mlast_name}{" "}
            {record.customer.last_name}</p>
          <p style={{ marginBottom: 0 }}><b>{'Num. Orden: '}</b> {`${record?.order_number || '--'}`}</p>
          <p style={{ marginBottom: 0 }}><b>{'Aseg: '}</b> {_.get(record, 'order_dynamic_data.insurance_type', null) && (record['order_dynamic_data'].insurance_type === 'P' ? 'Particular' : record['order_dynamic_data'].insurance?.name)}</p>
          {
            record?.current_location?.name && (
              <p style={{ marginBottom: 0 }}><b>{'Locación: '}</b> {`${record?.current_location?.name}`}</p>
            )
          }
          {
            record?.current_step?.assigned_to && (
              <p style={{ marginBottom: 0 }}><b>{'Técnico asignado: '}</b> {`${record?.current_step?.assigned_to?.first_name} ${record?.current_step?.assigned_to?.last_name}`}</p>
            )
          }
        </div>
      ),
    },
    {
      title: "Unidad",
      key: "vehicle",
      width: 300,
      render: (text, record) => (
        <div>
          <p style={{ marginBottom: 0 }}><b>{'Auto: '}</b> {record?.order_dynamic_data?.vehicle?.model?.brand?.name} {record?.order_dynamic_data?.vehicle?.model?.name}</p>
          <p style={{ marginBottom: 0 }}><b>{'VIN: '}</b> {record?.order_dynamic_data?.vehicle?.vin}</p>

          {record?.order_dynamic_data?.vehicle?.license_plate && <p style={{ marginBottom: 0 }}><b>{'Placas: '}</b> {record?.order_dynamic_data?.vehicle?.license_plate}</p>}
          {
            record?.order_dynamic_data?.mileage ? (
              <p style={{ marginBottom: 0 }}><b>{'Kilometraje: '}</b> {record?.order_dynamic_data?.mileage}</p>
            ) : null
          }
        </div>
      ),
    },
    {
      title: "F. Recepción",
      dataIndex: "created_at",
      key: "created_at",
      hidden: FilterFAlta.trueFilter,
      render: (record) => {
        let date = new Date(record);
        return moment(date).format("DD/MM/YYYY");
      },
    },
    {
      title: "F. Creación",
      dataIndex: "timestamp",
      key: "timestamp",
      hidden: FilterFAlta.trueFilter,
      render: (record) => {
        let date = new Date(record);
        return moment(date).format("DD/MM/YYYY");
      },
    },
    {
      title: "Valuación",
      key: "valuation",
      hidden: !settings.valuationIsActive,
      render: (record) => {
        if (record) {
          if (record.order_dynamic_data?.valuation?.status) {
            return <Tag style={{ cursor: 'pointer' }} onClick={() => gotoPage('/service-valuation/', record.id)} color={record.order_dynamic_data?.valuation?.status === 'PROGRESS' ? '#108ee9' : '#87d068'}>{record.order_dynamic_data?.valuation?.status === 'PROGRESS' ? 'En proceso' : 'Finalizado'}</Tag>
          }
        }
        return <a
          onClick={() => {
            gotoPage('/service-valuation/', record.id);
          }}
        >Sin iniciar</a>

      }
    },
    {
      title: "Costo",
      key: "cost",
      render: (record) => {
        if (record) {
          return <NumberFormat number={record.budget_cost} prefix={'$'} />
        }
      }
    },
    {
      title: "Precio",
      key: "price",
      render: (record) => {
        if (record) {
          return <NumberFormat number={record.budget_price} prefix={'$'} />
        }
      }
    },
    {
      title: "Reparación",
      key: "repair",
      render: (record) => {
        if (record?.is_deleted) {
          return 'Orden eliminada'
        }
        if (record) {
          if (record.process_status === 'REPAIR-COMPLETED') {
            return <Tag color={'#87d068'} style={{ cursor: 'pointer' }} onClick={() => gotoPage('/service-repair/', record.id)}>Rep. Finalizada</Tag>
          }
          if (record.order_dynamic_data?.current_step?.step?.name) {
            return <Tag style={{ cursor: 'pointer' }} onClick={() => gotoPage('/service-repair/', record.id)} icon={record?.order_dynamic_data?.current_step?.status === 'PLAY' ? <PauseCircleOutlined /> : <PlayCircleOutlined />}>{record?.order_dynamic_data?.current_step?.step?.name}</Tag>
          }
        }
        return <a
          onClick={() => {
            gotoPage('/service-repair/', record.id);
          }}
        >Sin iniciar</a>
      }
    },
    {
      title: "F. Promesa",
      dataIndex: "delivery_promise_date",
      key: "delivery_promise_date",
      render: (record) => {
        if (record) {
          let date = new Date(record);
          let hoy = new Date();
          return <span style={{ color: hoy > date ? 'red' : 'green' }}>{moment(date).format("DD/MM/YYYY")}</span>
        }
        return '--'
      }
    },
  ].filter(item => !item.hidden);


  if (tenantSelected === 'jacmexico') {
    columns.unshift(
      {
        title: "Vin",
        dataIndex: "Vin",
        key: "Vin",
        render: (text, ele) => {
          return ele?.order_dynamic_data?.vehicle?.vin || '--'
        },
      },
      {
        title: "Locación",
        dataIndex: "location",
        key: "location",
        render: (text, ele) => {
          return ele?.current_location?.name || '--'
        },
      },

    )
  }

  const gotoPage = (url, id) => {
    history.push(url + id)
  }

  const validateStatus = (status) => {
    switch (status) {
      case 'I':
        return <Tag color="#108ee9">Ingreso</Tag>;
      case 'CLOSED':
        return <Tag color="#87d068">Entregado</Tag>
      case 'OPEN':
        return <Tag color="#108ee9">Abierto</Tag>
      default:
        return '--'
    }
  }

  const menuColumns = () => (
    <Menu>
      <Menu.Item key="1">
        <Checkbox
          onChange={FilterFolio.changeTrueFilter}
          checked={!FilterFolio.trueFilter}
        >
          Folio
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="2">
        <Checkbox
          onChange={FilterMarca.changeTrueFilter}
          checked={!FilterMarca.trueFilter}
        >
          <FormattedMessage id="layout.brand" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="3">
        <Checkbox onChange={FilterModelo.changeTrueFilter} checked={!FilterModelo.trueFilter}>
          <FormattedMessage id="layout.model" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="4">
        <Checkbox onChange={FilterAsesor.changeTrueFilter} checked={!FilterAsesor.trueFilter} >
          <FormattedMessage id="services.page.advisor" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="5">
        <Checkbox onChange={FilterCliente.changeTrueFilter} checked={!FilterCliente.trueFilter} >
          <FormattedMessage id="services.page.costumer" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="6">
        <Checkbox onChange={FilterFAlta.changeTrueFilter} checked={!FilterFAlta.trueFilter} >F. Alta</Checkbox>
      </Menu.Item>
      <Menu.Item key="7">
        <Checkbox onChange={FilterStatus.changeTrueFilter} checked={!FilterStatus.trueFilter} >
          <FormattedMessage id="services.page.status" />
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="8">
        <Checkbox onChange={FilterAction.changeTrueFilter} checked={!FilterAction.trueFilter} >
          {" "}
          <FormattedMessage id="layout.actions" />
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  const getServices = async (page, page_size, filters = '') => {
    setLoading(true);
    setCount(null)
    try {
      let newFilters = filters
      if (!newFilters.includes('&is_deleted')) {
        newFilters += '&is_deleted=false'
      }
      const res = await axiosApi.get(API.WORKSHOP.WORKSHOP_ORDER_LIST + `&is_light_serializer=1&page=${page}&page_size=${page_size}${newFilters}`);
      console.log("services", res);
      if (res.data.results) {
        setPagination({
          ...pagination,
          total: res.data.count,
          current: page,
          pageSize: page_size,
        });
        setCount(res.data.count)
        setServices(res.data.results);
      }
    } catch (e) {
      setCount(null)
      //alert(intl.formatMessage({ id: "services.page.err" }) + e);
    } finally {
      setLoading(false);
    }
  };

  const onFilterAdvance = (params) => {
    getServices(1, 10, (currentFSearch ? currentFSearch : '') + '&' + params)
    setModalFilter(false)
    setCurrentFilter('&' + params)
  }

  const getReport = async () => {
    setLoadingReport(true)
    try {
      let url = `/service/order/workshop_download_excel/?order_type__code=WORKSHOP&is_deleted=false${currentFilter}`;
      if (currentFSearch) {
        url += `${currentFSearch}`;
      }
      console.log('URL', url)
      const res = await axiosApi.get(`${url}`, { responseType: 'blob' })
      downloadFile(res.data, `report_services`, 'application/vnd.ms-excel')
      console.log(res)
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingReport(false)
    }
  }

  const getJacReport = async () => {
    setLoadingJACBitacora(true)
    try {
      let url = `/service/order/workshop_download_jac_excel/?order_type__code=WORKSHOP&is_deleted=false${currentFilter}`;
      console.log('URL', url)
      const res = await axiosApi.get(`${url}`, { responseType: 'blob' })
      console.log("response", res)
      downloadFile(res.data, `bitacora`, 'application/vnd.ms-excel')
      console.log(res)
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingJACBitacora(false)
    }
  }

  const changePagination = (data) => {
    getServices(data.current, data.pageSize, '&' + currentFilter);
  };

  // para guardar una vista customizada
  const saveCustomView = async (values) => {
    try {
      const res = await axiosApi.post(API.BUSINESS.CUSTOM_FILTER, values)
      message.success('Guardado correctamente')
      setModalFilter(false)
      getCustomViews()
    } catch (e) {
      console.log(e)
    }
  }




  const deleteView = async (viewId) => {
    console.log('eliminado ', viewId)
    setLoadingViews(true)
    try {
      const res = await axiosApi.delete(API.BUSINESS.CUSTOM_FILTER + viewId + '/')
      message.success('Eliminado correctamente')
      getCustomViews()
      console.log(res)
    } catch (e) {
      console.log(e)
    } finally {
      setLoadingViews(false)
    }
  }


  const hoverContent = (itemView) => <div><KButton loading={loadingViews} text='Eliminar vista' size='small' onClick={() => deleteView(itemView.id)} /></div>;
  const handleHoverChange = (value) => {
    console.log(value)
  };


  return (
    <MainLayoutv2
      title={intl.formatMessage({ id: "services.service" })} index="expedientes">
      <Layout style={{ marginTop: 10 }}>
        {/*<TabsOptions />*/}
        <Row gutter={[10]} justify="space-between" style={{ marginTop: 20 }}>
          <Col flex="auto">
            <Form size="large">
              <Form.Item extra={<div>{` ${'    '}Total: ${count ? count : 0} `}
                {
                  (currentFilter !== '&') ? <Tooltip title="Guardar vista"><KButton size={'small'} type={'outline'} icon={<SaveOutlined />} onClick={() => setShowModalFilterView(true)} /> </Tooltip> : null
                }
              </div>} className="pt-0">
                <Search
                  size="large"
                  onSearch={(value) => {
                    setCurrentSearch(`&search=${value}`)
                    getServices(1, 10, `&search=${value}`)
                  }}
                  allowClear
                  placeholder={intl.formatMessage({ id: 'services.searchtext' })}
                  className="kinput-search"
                  style={{ height: 50 }}
                />

              </Form.Item>
            </Form>
          </Col>
          {/* <Col> Non responsive buttons:
            <Space direction="horizontal">
              <KButton size="large" icon={<ControlOutlined />} text={<FormattedMessage id="services.page.filter" />} type={'outline'} onClick={() => setModalFilter(true)} />
              <KButton size="large" icon={<FileExcelOutlined />} text={<FormattedMessage id="services.page.report" />} onClick={() => getReport()} />
              {
                tenantSelected === "jacmexico" && (
                  <KButton size="large" icon={<FileExcelOutlined />} text={<FormattedMessage id="services.page.jacreport" />} onClick={() => getJacReport()} />
                )
              }

              <KButton size="large" type={'secondary'} icon={<PlusCircleOutlined />} text={ <FormattedMessage id="layout.createNew" />} onClick={() => history.push("/services/new"+(tenantSelected === "jacmexico_"?'?type=jac':''))} />
            </Space>
          </Col> */}
          <Col>
            <Row justify="center" gutter={[16, 16]}>
              <Col><KButton size="large" icon={<ControlOutlined />} text={<FormattedMessage id="services.page.filter" />} type={'outline'} onClick={() => setModalFilter(true)} /></Col>
              <Col><KButton size="large" loading={loadingReport} icon={<FileExcelOutlined />} text={<FormattedMessage id="services.page.report" />} onClick={() => getReport()} /></Col>
              {
                tenantSelected === "jacmexico" && (
                  <Col><KButton size="large" icon={<FileExcelOutlined />} loading={loadingJACBitacora} text={<FormattedMessage id="services.page.jacreport" />} onClick={() => getJacReport()} /></Col>
                )
              }
              <Col><KButton size="large" type={'secondary'} icon={<PlusCircleOutlined />} text={<FormattedMessage id="layout.createNew" />} onClick={() => history.push("/services/new" + (tenantSelected === "jacmexico_" ? '?type=jac' : ''))} /></Col>
            </Row>
          </Col>
          <Col span={24}>
            <Spin spinning={loadingViews}>
              {
                customViewsList &&
                <Row gutter={8}>
                  {
                    customViewsList.map((itemView) => {
                      return <Col>
                        {
                          itemView.created_by_id === props.user.id ? <Popover
                            style={{
                              width: 500,
                            }}
                            content={hoverContent(itemView)}
                            trigger="hover"
                            open={hovered}
                          >
                            <KButton icon={<FilterOutlined />} onClick={() => onFilterAdvance(itemView.filter)} size={'small'} type={'outline'} text={itemView.name} />
                          </Popover> : <KButton icon={<FilterOutlined />} onClick={() => onFilterAdvance(itemView.filter)} size={'small'} type={'outline'} text={itemView.name} />
                        }

                      </Col>
                    })
                  }
                </Row>
              }
            </Spin>



          </Col>
          <Col span={24} style={{ paddingTop: 20 }}>
            <CustomTable
              fixed={true}
              scroll={{ x: 1500 }}
              columns={columns}
              dataSource={services ? services : []}
              loading={loading}
              pagination={pagination}
              onChange={changePagination}
            />
          </Col>
        </Row>
        <FilterServicesModal onClear={() => {
          getServices(1, 10)
          setModalFilter(false)
          setCurrentFilter('&')
          setCurrentSearch(null)
        }} onFinish={onFilterAdvance} />
        <CreateFilterViewModal
          onSave={saveCustomView}
          filterStr={currentFilter}
          handleOk={() => console.log('hola')}
          viewCode={VIEWCODE}
          handleCancel={() => setShowModalFilterView(false)}
          isModalVisible={showModalFilterView} />
      </Layout>
    </MainLayoutv2>
  );
};

const mapState = (state) => ({
  servicesStatus: state.services,
  settings: state.settings,
  user: state.user
});

export default injectIntl(connect(mapState, { setModalFilter, changeLangAction })(ServicePage)
);
