import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import DashboardLayout from "../../../layouts/DashboardLayout";
import TabsOptions from "../../../components/dashboard/TabsOptions";
import { Divider, Layout, Row, Col, Table, Typography, Avatar } from "antd";
import { Global, css } from '@emotion/react';
import Title from 'antd/lib/skeleton/Title';
import { UserOutlined } from "@ant-design/icons";


const Electroplan = () => {

    const {Text} = Typography

    const TitleColumn = ({ title = null, orange=0, yellow=0, green=0 }) => {
      return (
        <div className="containerTitle">
          <div style={{ height: 44, display: "flex" }}>
            <Text style={{ color: "white", margin: "auto", fontSize: 12 }}>
              {title}
            </Text>
          </div>
          <div style={{ marginBottom: 5 }}>
            <span className="spanOrange">{orange}</span>
            <span className="spanYellow">{yellow}</span>
            <span className="spanGreen">{green}</span>
          </div>
        </div>
      );
    };

    const ColumnContent = ({row}) => {
        return (
            <div style={{ lineHeight: "15px", padding: "10px", background:'orange', borderRadius:10 }}>
                <Text strong>{row.car.model}</Text> <br />
                <Text>{row.car.owner.name}</Text> <br />
                <Text>Estado: {row.car.status}</Text> <br />
                <Text>{row.car.time}</Text> <br />
                <div style={{width:'100%', textAlign:'center', marginTop:10}} >
                    <Avatar size="small" icon={<UserOutlined />} />
                </div>
              </div>
        )
    }

    const columns = [
      {
        title: () => {
          return <TitleColumn title="Unidad" orange={1} yellow={6} green={4} />;
        },
        className: "card_main",
        render: (row) => (
          <div style={{ lineHeight: "15px" }}>
            <Text
              style={{
                color: "#2D2CF5",
                fontSize: 12,
                textDecoration: "underline",
              }}
            >
              {row.folio}
            </Text>{" "}
            <br />
            <small>
              <Text strong>Orden:</Text> 21312
            </small>{" "}
            <br />
            <small> YWBCD23</small> <br />
            {/* <small>Pirámide: 6</small> <br /> */}
            <small>NARANJA</small>
          </div>
        ),
      },
      {
        title: () => {
          return (
            <TitleColumn title="Alineación" orange={1} yellow={6} green={4} />
          );
        },
        render: (row) => (
          <>{row.car && row.car.service === 1 && <ColumnContent row={row} />}</>
        ),
      },
      {
        title: () => {
          return (
            <TitleColumn title="Desarmado" orange={2} yellow={0} green={3} />
          );
        },
        render: (row) => (
          <>{row.car && row.car.service === 2 && <ColumnContent row={row} />}</>
        ),
      },
      {
        title: () => {
          return (
            <TitleColumn title="Bancada" orange={1} yellow={6} green={4} />
          );
        },
        render: (row) => (
          <>{row.car && row.car.service === 3 && <ColumnContent row={row} />}</>
        ),
      },
      {
        title: () => {
          return (
            <TitleColumn title="Ojalatería" orange={1} yellow={6} green={4} />
          );
        },
        render: (row) => (
          <>{row.car && row.car.service === 4 && <ColumnContent row={row} />}</>
        ),
      },
      {
        title: () => {
          return (
            <TitleColumn
              title="Mecánica de colision"
              orange={1}
              yellow={6}
              green={4}
            />
          );
        },
        render: (row) => (
          <>{row.car && row.car.service === 5 && <ColumnContent row={row} />}</>
        ),
      },
      {
        title: () => {
          return (
            <TitleColumn title="Preparación" orange={1} yellow={6} green={4} />
          );
        },
        render: (row) => (
          <>{row.car && row.car.service === 6 && <ColumnContent row={row} />}</>
        ),
      },
      {
        title: () => {
          return (
            <TitleColumn title="Pintura" orange={1} yellow={6} green={4} />
          );
        },
        render: (row) => (
          <>{row.car && row.car.service === 7 && <ColumnContent row={row} />}</>
        ),
      },
      {
        title: () => {
          return (
            <TitleColumn title="Armnado" orange={1} yellow={6} green={4} />
          );
        },
        render: (row) => (
          <>{row.car && row.car.service === 8 && <ColumnContent row={row} />}</>
        ),
      },
      {
        title: () => {
          return <TitleColumn title="Lavado" orange={1} yellow={6} green={4} />;
        },
        render: (row) => (
          <>{row.car && row.car.service === 9 && <ColumnContent row={row} />}</>
        ),
      },
      {
        title: () => {
          return (
            <TitleColumn
              title="Recorrido de prueba"
              orange={1}
              yellow={6}
              green={4}
            />
          );
        },
        render: (row) => (
          <>
            {row.car && row.car.service === 10 && <ColumnContent row={row} />}
          </>
        ),
      },
      {
        title: () => {
          return <TitleColumn title="Pulido" orange={1} yellow={6} green={4} />;
        },
        render: (row) => (
          <>
            {row.car && row.car.service === 11 && <ColumnContent row={row} />}
          </>
        ),
      },
      {
        title: () => {
          return (
            <TitleColumn
              title="Control de calidad"
              orange={1}
              yellow={6}
              green={4}
            />
          );
        },
        render: (row) => (
          <>
            {row.car && row.car.service === 12 && <ColumnContent row={row} />}
          </>
        ),
      },
      {
        title: () => {
          return (
            <TitleColumn
              title="Auto terminado"
              orange={1}
              yellow={6}
              green={4}
            />
          );
        },
        render: (row) => (
          <>{row.car && row.car.service === 13 && <ColumnContent row={row} />}</>
        ),
      },
    ];


    const dataInfo = [
      {
        folio: "E2-115998",
        order: 1234,
        matricula: "YNW374d",
        piramide: 6,
        color: "Naranja",
        car: {
          service: 2,
          model: "Kia rio",
          status: "Stop",
          time: "18 dias",
          owner: {
            name: "Roberto may",
          },
        },
      },
      {
        folio: "E2-115998",
        order: 1234,
        matricula: "YNW374d",
        piramide: 6,
        color: "Naranja",
        car: {
          service: 5,
          model: "VOLKSWAGEN CABRIO",
          status: "Pausa",
          time: "63 dias",
          owner: {
            name: "Carlos Salazar",
          },
        },
      },
      {
        folio: "E2-115998",
        order: 1234,
        matricula: "YNW374d",
        piramide: 6,
        color: "Naranja",
        car: {
          service: 11,
          model: "VOLKSWAGEN VENTO",
          status: "Stop",
          time: "18 dias",
          owner: {
            name: "Roberto may",
          },
        },
      },
      {
        folio: "E2-115998",
        order: 1234,
        matricula: "YNW374d",
        piramide: 6,
        color: "Naranja",
        car: {
          service: 1,
          model: "VOLKSWAGEN VENTO",
          status: "Stop",
          time: "18 dias",
          owner: {
            name: "Roberto may",
          },
        },
      },
      {
        folio: "E2-115998",
        order: 1234,
        matricula: "YNW374d",
        piramide: 6,
        color: "Naranja",
        car: {
          service: 9,
          model: "VOLKSWAGEN VENTO",
          status: "Stop",
          time: "18 dias",
          owner: {
            name: "Roberto may",
          },
        },
      },
      {
        folio: "E2-115998",
        order: 1234,
        matricula: "YNW374d",
        piramide: 6,
        color: "Naranja",
        car: {
          service: 4,
          model: "VOLKSWAGEN VENTO",
          status: "Stop",
          time: "18 dias",
          owner: {
            name: "Roberto may",
          },
        },
      },
    ];

    return (
      <>
        <Global
          styles={css`
            th.ant-table-cell {
              padding: 0px !important;
              width: calc(100%/14) !important;
              background: transparent;
            }
            td.ant-table-cell {
              padding: 0px !important;
              width: calc(100%/14) !important
            }
            td.ant-table-cell{
                padding: 0px !important;
                word-break: break-word;
                border-right: solid .5px #dbdbdb;
            }
            
            
            .tableElectroplan .ant-table {
              background: transparent;
            }
            .tableElectroplan tbody{
                margin-top:30px;
            }
            .tableElectroplan
              .ant-table-container
              .ant-table-content
              table
              thead.ant-table-thead
              tr
              th.ant-table-cell .containerTitle {
              text-align: center;
              background: #08082f;
              color: white;
              border-radius: 6px;
              border: solid 1px white;
              margin-bottom: 15px;
            }
            .spanOrange {
              background: #7dd61d !important;
              padding: 2px 5px !important;
              border-radius: 5px !important;
              color: black;
            }
            .spanYellow {
              background: #ffea46 !important;
              padding: 2px 5px !important;
              border-radius: 5px !important;
              color: black;
              margin: 0 5px;
            }
            .spanGreen {
              background: #ff6966 !important;
              padding: 2px 5px !important;
              border-radius: 5px !important;
              color: black;
            }
            .table-row-gray{
                background-color: #2f8eed12 !important;
            }
          `}
        />
        <DashboardLayout title="Electroplán" index="Expedientes">
          <Layout style={{ marginTop: 50 }}>
            <TabsOptions />
            <Divider style={{ margin: "15px 0px" }} />
            <Table
              className="tableElectroplan"
              columns={columns}
              dataSource={dataInfo}
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-white" : "table-row-gray"
              }
            />
            <Row>
              {/* <Col span={24}>
                <Table className="tableElectroplan" columns={columns} />
              </Col> */}
              {/* <Col flex={1} style={{ background: "red" }}>
                Col1Col1Col1Col1Col1 Col1
              Col1</Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col>
              <Col flex={1} style={{ background: "red" }}>
                Col1
              </Col> */}
            </Row>
          </Layout>
        </DashboardLayout>
      </>
    );
}


export default injectIntl(Electroplan)
