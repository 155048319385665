import axiosApi, { API } from "../lib/utils/axiosApi";

export const getWorkshopEntranceList = async (orderId) => {
  const response = await axiosApi.get(API.WORKSHOP.WORKSHOP_GET_ENTRY_LIST.replace('{orderId}', orderId));
  return response.data;
};

export const deleteWorkshopEntrance = async (entranceId) => {
  const response = await axiosApi.delete(API.WORKSHOP.WORKSHOP_DELETE_ENTRY.replace('{entranceId}', entranceId));
  return response.data;
};