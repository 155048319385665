import { Select, Form } from "antd";
import { useSelector } from "react-redux";
import { useEffect } from "react";
const SelectResponsables = ({name='current_step__assigned_to', label='Responsable'}) => {
    const usersWorkFlow = useSelector(state => state.workflow.usersWorkflow);


    return (
        <Form.Item
                  label={label}
                  name={name}
                  className="item-expediente-lg mb-0 label-margin"
        >
        <Select
            placeholder="Selecciona un responsable"
            style={{ width: '100%' }}
            allowClear
            filterOption={(input, option) => {
                const userFullName = option?.children?.toLowerCase() || '';
                return userFullName.includes(input.toLowerCase());
            }}
            optionFilterProp="children"
            showSearch
        >
            {usersWorkFlow?.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                    {`[${user?.group?.name || ''}] - ${user.first_name} ${user.last_name}`}
                </Select.Option>
            ))}
        </Select>
        </Form.Item>
    )
}   

export default SelectResponsables;