import { Table } from 'antd'

const TableEntrance = ({ dataSource, columns }) => {
    return (
        <div>
            <Table dataSource={dataSource} 
            columns={columns} />
        </div>
    )
}

export default TableEntrance