import { useState } from "react"
import { message, Space, Button, Tooltip, Popconfirm } from "antd"
import { EyeOutlined, EditOutlined, DeleteOutlined, FilePdfOutlined } from "@ant-design/icons"
import { getWorkshopEntranceList as getWorkshopEntranceListService, deleteWorkshopEntrance } from "../../../../services/entryServices"
import { useHistory } from "react-router-dom"
import { axiosApi, urlfull } from "../../../../lib/utils/axiosApi";
import { downloadFile } from "../../../../lib/utils/utils";
import moment from 'moment'

const useWorkShopList = (orderId= null) => {

    const [loading, setLoading] = useState(false)
    const [entranceList, setEntranceList] = useState([])
    const [loadingReportId, setLoadingReportId] = useState(null)
    const history = useHistory()

    const handleDelete = async (entranceId) => {
        setLoading(true)
        try{
            const response = await deleteWorkshopEntrance(entranceId)
            console.log(response)
            message.success('Registro eliminado correctamente')
            getWorkshopEntranceList(orderId)
        }catch(error){
            message.error(error.message)
        }finally{
            setLoading(false)
        }
    }


    const downloadInventoryReport = async (orderId, entranceId) => {
        setLoadingReportId(entranceId)
        try {
            const res = await axiosApi.get(`${urlfull}service/order/${orderId}/build_report/?type=ENTRY_INVENTORY&entry_id=${entranceId}`, { responseType: 'blob' })
            downloadFile(res.data, `inventario_${moment().format('YYYY-MM-DD')}`, 'application/pdf')
            console.log(res)
        } catch (e) {
            console.log(e)
        } finally {
            setLoadingReportId(null)
        }
    }

    const handleEdit = (entranceId) => {
        history.push(`/workshop-entrance/${orderId}?entry_id=${entranceId}`)
    }

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (text) => {
                return <span>{new Date(text).toLocaleString()}</span>
            }
        },
        {
            title:'Recibido por',
            dataIndex: 'received_by',
            key: 'received_by',
            render: (text) => {
                return <span>{text.first_name} {text.last_name}</span>
            }
        },
        {
            title:'Daños previos',
            dataIndex: 'pre_existing_damages',
            key: 'pre_existing_damages',
        },
        {
            title:'Otros Accesorios',
            dataIndex: 'other_accessories',
            key: 'other_accessories',
        },
        {
            title:'Comentarios del cliente',
            dataIndex: 'comments_by_customer',
            key: 'comments_by_customer',
        },
        {
            title: 'Kilometraje',
            dataIndex: 'mileage',
            key: 'mileage',
            render: (text) => {
                return <span>{text} km</span>
            }
        },
        {
            title:'Acciones',
            key: 'actions',
            render: (ele) => {
                return <Space>
                    <Tooltip title="Editar">
                        <Button onClick={() => handleEdit(ele?.id)} type="link" style={{ color: 'blue' }} icon={<EditOutlined />}></Button>
                    </Tooltip>
                    
                    {/* Popconfirm */}
                    <Popconfirm title="¿Estás seguro de que deseas eliminar este registro?" onConfirm={() => handleDelete(ele?.id)}>
                        <Tooltip title="Eliminar">
                            <Button type="link" style={{ color: 'red' }} icon={<DeleteOutlined />}></Button>
                        </Tooltip>
                    </Popconfirm>   
                    <Tooltip title="Reporte">
                        <Button loading={loadingReportId === ele?.id} type="link" onClick={() => downloadInventoryReport(orderId, ele?.id)} style={{ color: '#800024' }} icon={<FilePdfOutlined />}></Button>
                    </Tooltip>
                </Space>
            }
        }
    ]

    const getWorkshopEntranceList = async (_orderId= null) => {
        setLoading(true)
        try{
            const response = await getWorkshopEntranceListService(_orderId)
            setEntranceList(response?.results)
        }catch(error){
            setLoading(false)
            message.error(error.message)
        }finally{
            setLoading(false)
        }
    }

    return { entranceList, columns, loading,downloadInventoryReport, getWorkshopEntranceList }
}

export default useWorkShopList