import React, { useEffect } from 'react'
import { Row, Col, Card, Button, Layout, Divider } from 'antd'
import MainLayoutv2 from '../../../layouts/MainLayoutv2';
import MenuServiceDetail from '../../../components/services/MenuServiceDetail';
import useWorkShopList from './hooks/useWorkShopList';
import { useParams, useHistory } from 'react-router-dom';
import KButton from '../../../components/common/elements/KButton/KButton';
import TableEntrance from './components/TableEntrance';

const PageWorkshopEntranceList = () => {
    const { id } = useParams()
    const history = useHistory()
    const { entranceList,
        columns,
        loading,
        getWorkshopEntranceList
    } = useWorkShopList(id)

    useEffect(() => {
        if (id) {
            console.log(id)
            getWorkshopEntranceList(id)
        }
    }, [id])
    return (
        <MainLayoutv2
            isServiceDetail={true}
            index={'expedientes'}
            title={'Registro de entrada'}
        >
            <Layout style={{ marginTop: 10 }}>

                <Card
                    className="card_details_service"
                >
                    <MenuServiceDetail menuSelected={1} />
                    <Divider />
                    <Row gutter={16} style={{ marginBottom: 10 }}>
                        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <KButton
                                type={'primary'}
                                text={'+ Registrar entrada'}
                                onClick={() => {
                                    history.push(`/workshop-entrance/${id}/?v2=true`)
                                }}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={24}>
                            <TableEntrance
                                dataSource={entranceList}
                                columns={columns}
                                loading={loading}
                            />
                        </Col>
                    </Row>
                </Card>

            </Layout>
        </MainLayoutv2>
    )
}

export default PageWorkshopEntranceList