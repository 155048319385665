import React, { useState, useEffect } from 'react'
import DashboardLayout from "../../layouts/DashboardLayout";
import {FormattedMessage} from "react-intl";
import AxiosApi, {API, tenantSelected,urlfull} from "../../lib/utils/axiosApi";
import { AuditOutlined, FilePdfOutlined, FileTextOutlined, DollarCircleFilled, FileMarkdownFilled, UsbOutlined , UploadOutlined} from '@ant-design/icons';
import {  Row,Button, Col,Space, Card, Typography, Descriptions, Badge, Spin, Upload,Progress, message, Modal, Table } from 'antd';
import {CustomTable} from "../../components/KComponents";
import moment from 'moment'
import { useSelector } from 'react-redux';
import NumberFormat from "../../components/common/NumberFormat";
import cookie from "js-cookie";
import MainLayoutv2 from '../../layouts/MainLayoutv2';

const { Title } = Typography;

const months=['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']

const MembershipPage=()=>{

    const [dataPlan, setDataPlan] = useState(null)
    const [diskStatus, setDiskStatus] = useState(null)
    const companySelected = useSelector(state => state?.settings?.settingsData?.companySelected);
    const [loading, setLoading] = useState(false)
    const [plan, setPlan] = useState(false)
    const [pendingPayments, setPendingPayments] = useState(0)
    const [pendingAmount, setPendingAmount] = useState(0)
    const [diskPercent, setDiskPercent] = useState(0)
    const [headers, setHeaders] = useState({})
    let url = `collection/payment-order/`

    const [pagination, setPagination] = useState({
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
    });

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleChange =(info)=> {
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
            setLoading(true)
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} Subido correctamente`);
            getMemberShipData(pagination.current, pagination.pageSize)
            setLoading(false)
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} Hubo un error al subir el archivo, intenta mas tarde.`);
            setLoading(false)
        }
    };

    const getDiskStatus= async ()=>{
        setLoading(true)
        try{
            console.log('Headers usados en la petición:', headers);
            const res = await AxiosApi.get(
                `${API.BUSINESS.TENANT_STATUS.replace('{tenant_name}',tenantSelected)}?v=${new Date().getTime()}`
            );
            console.log('company',res.data)
            setDiskStatus(res.data)
            if(res.data?.cloud_storage_used){
               let percent = (res.data?.cloud_storage_used*100)/res.data?.cloud_storage_size;
               setDiskPercent(percent)
            }
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const getCompanyPlan= async ()=>{
        setLoading(true)
        try{
            const res = await AxiosApi.get(API.BUSINESS.BRANCH+companySelected?.id+'/');
            console.log('company',res.data)
            setPlan(res.data?.plan)
        }catch (e){
            setPlan(null)
        }finally {
            setLoading(false)
        }
    }

    const processPayments=(payments)=>{
        console.log('payments',payments)
        let amountPending =0;
        let pendingPayments = payments.filter((item)=> item.status===0);
        console.log('pendientes',pendingPayments)
        if(pendingPayments){
            pendingPayments.forEach((ele)=>{
                amountPending = amountPending + parseFloat(ele.amount)
            })

            setPendingAmount(amountPending)
            setPendingPayments(pendingPayments.length)
        }

    }


    const getMemberShipData = async (page, page_size=1)=>{
        setLoading(true)
        try {
            const res = await AxiosApi.get(API.BUSINESS.MEMBERSHIP+ `?page=${page}&page_size=${page_size}`);
            console.log(res.data)
            if(res.data?.results){
                setDataPlan(res.data.results)
                processPayments(res.data.results)
                setPagination({
                    ...pagination,
                    total: res.data.count,
                    current: page,
                    pageSize: page_size,
                });
            }

        }catch (e){
            setDataPlan(null)
        }finally {
            setLoading(false)
        }
    }


    useEffect(()=>{
        getMemberShipData(pagination.current, pagination.pageSize)
        getCompanyPlan()
        getDiskStatus()
        getHeaders()
    },[])

    const getHeaders=()=>{
        let token = cookie.get('token') ? cookie.get('token') : '';
        let settings = localStorage.getItem('settings_kk');
        let headers = {};

        if(settings){
            try{
                settings = JSON.parse(settings)
                if(settings.companySelected){
                    headers['company'] = settings.companySelected.id
                }
                if(settings.branchSelected){
                    headers['branch'] = settings.branchSelected.id
                }
            }catch (e){
                console.log('error')
            }
        }

        if(token) headers.Authorization = `JWT ${token}`;

        console.log('Headers configurados:', headers);
        setHeaders(headers)
    }



    const changePagination = (data) => {
        getMemberShipData(data.current, data.pageSize);
    };

    const showDetails = (record) => {
        setSelectedItem(record);
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
        setSelectedItem(null);
    };

    const columns = [
        {
            title: 'Folio',
            dataIndex: 'invoice_folio',
        },
        {
            title: 'Periodo de facturación',
            key: 'date',
            render:(item)=> (
                <p>{item?.period_month ? months[item?.period_month-1]: 'N/A'} {item?.period_year}  </p>
            )
        },
        {
            title: 'Factura',
            key: 'billings',
            render: (item)=>  (<>
                {
                    item?.invoice_xml && <a href={item.invoice_xml} target={'_blank'} download style={{color: 'blue', marginRight: 10}}>
                        <FileTextOutlined/> XML
                    </a>
                }

                {item.invoice_pdf &&
                    <a type="link" target={'_blank'} href={item.invoice_pdf} download style={{color: 'red'}}>
                        <FilePdfOutlined/> PDF
                    </a>
                }
            </>)
        },
        {
            title: 'Status',
            key: 'status',
            render:(item)=> (
                <p>{item.is_paid ? <Badge status="success" />:<Badge status="warning" /> } {item.is_paid ? 'Pagado':'Pendiente de pago' }  </p>
            )
        },
        {
            title: 'Fecha de pago',
            key: 'payment_date',
            render:(item)=> (
                <p>{item.payment_date ? moment(item.payment_date).format('DD/MM/YYYY') : '--'} </p>
            )
        },
        {
            title: 'Comprobante de pago',
            key: 'vaoucher',
            render:(item)=> (
                <>


                    {
                        !item.is_paid &&  <Upload method={'post'}
                                                  showUploadList={false}
                                                  multiple={false}
                                                  headers={headers}
                                                  name={'payment_proof'} action={`${urlfull+url+item.id}/upload-payment-proof/`} onChange={handleChange} >
                            <Button icon={<UploadOutlined />}>Subir</Button>
                        </Upload>
                    }


                    {
                        ' '
                    }
                    {
                        item?.payment_proof ? <a href={item.payment_proof} target={'_blank'} download style={{color: 'blue', marginRight: 10}}>
                            <AuditOutlined /> Ver comprobante de pago
                        </a> :null
                    }

                </>

            )
        },
        {
            title: 'Total (Incluye IVA)',
            key: 'total',
            render:(item)=> (
                <NumberFormat suffix={'MXN'} number={item.total} prefix={'$'}/>
            )
        },
        {
            title: 'Detalles',
            key: 'details',
            render: (text, record) => (
                <Button 
                    type="link" 
                    onClick={() => showDetails(record)}
                    icon={<FileTextOutlined />}
                >
                    Ver detalles
                </Button>
            )
        },
    ];

    const data = [
        {
            date: new Date(),
            status: 'Activo',
            amount: 4500,
        }
    ]

    const detailColumns = [
        {
            title: 'Cantidad',
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: 'Descripción',
            dataIndex: 'product_description',
            key: 'product_description',
            width: '30%',
        },
        {
            title: 'Código Producto',
            dataIndex: 'product_code',
            key: 'product_code',
        },
        {
            title: 'Código Unidad',
            dataIndex: 'unit_code',
            key: 'unit_code',
        },
        {
            title: 'Valor Unitario',
            dataIndex: 'unit_value',
            key: 'unit_value',
            render: (value) => <NumberFormat suffix={'MXN'} number={value} prefix={'$'}/>
        },
        {
            title: 'Subtotal',
            dataIndex: 'subtotal',
            key: 'subtotal',
            render: (value) => <NumberFormat suffix={'MXN'} number={value} prefix={'$'}/>
        },
        {
            title: 'IVA',
            dataIndex: 'taxes',
            key: 'taxes',
            render: (value) => <NumberFormat suffix={'MXN'} number={value} prefix={'$'}/>
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (value) => <NumberFormat suffix={'MXN'} number={value} prefix={'$'}/>
        }
    ];

   return (<MainLayoutv2 title={'Membresía'} >
       <Row gutter={32}>
           <Col md={12} xs={24}>
               <Spin spinning={loading}>
               <Card title={<Title level={3}  className={'text-primary'}><FileMarkdownFilled style={{marginRight:15}}/>Plan contratado</Title>} style={{ width: '100%', borderRadius:15 }}>
                   <Descriptions title=" ">
                   <Descriptions.Item label="Plan">{plan && plan.name}</Descriptions.Item>
                   </Descriptions>
                   <Descriptions>
                   <Descriptions.Item label="Precio">{plan && <NumberFormat suffix={'MXN'} number={plan.price} prefix={'$'}/>} {' / '} {plan && plan.type} <small>{' '}+ IVA</small>
                   </Descriptions.Item>
                   </Descriptions>

               </Card>
               </Spin>
           </Col>
           <Col md={12} xs={24}>
               <Spin spinning={loading}>
                   <Card title={<Title level={3}  className={'text-primary'}><UsbOutlined style={{marginRight:15}}/>Almacenamiento</Title>} style={{ width: '100%', borderRadius:15}}>
                       <br/>
                       <Progress 
                           percent={diskPercent ? diskPercent.toFixed(2) : 0} 
                           status="active" 
                           strokeColor={
                               diskPercent >= 100 
                               ? {
                                   '0%': '#ff4d4f',
                                   '100%': '#ffa39e'
                               }
                               : {
                                   '0%': '#108ee9',
                                   '100%': '#87d068'
                               }
                           } 
                       />
                       <p className={'text-primary'} style={{height:40}}>
                           {`${diskStatus?.cloud_storage_used}GB usados de ${diskStatus?.cloud_storage_size}GB contratados`}
                       </p>

                   </Card>
               </Spin>
           </Col>
{/*            <Col md={5} xs={24}>
               <Spin spinning={loading}>
                   <Card style={{ width: '100%', borderRadius:15, padding:20 }}>
                       <Space>
                           <Statistic  title="Facturas pendientes de pago" value={pendingPayments} />
                       </Space>
                   </Card>
               </Spin>
           </Col>
           <Col md={5} xs={24} >
               <Spin spinning={loading}>
                   <Card style={{ width: '100%', borderRadius:15, padding:20 }}>
                       <Space>
                           <Statistic prefix={'$'} suffix={'MXN'} title="Total pendiente de pago" value={pendingAmount? pendingAmount.toFixed(2):0} />
                       </Space>
                   </Card>
               </Spin>
           </Col> */}

           <Col span={23} style={{background:'white',padding:15, borderRadius:15, marginTop:30,marginLeft:20}}>
               <Title level={3} className={'text-primary'}><DollarCircleFilled style={{marginRight:10}} />    Mis Pagos</Title>

               <p>uso {diskPercent}</p>
               <CustomTable
                   onChange={changePagination}
                   pagination={pagination} loading={loading} columns={columns} dataSource={dataPlan} size="small" />
           </Col>

       </Row>
       <Modal
           title="Detalles de facturación"
           visible={isModalVisible}
           onCancel={handleModalClose}
           footer={[
               <Button key="close" onClick={handleModalClose}>
                   Cerrar
               </Button>
           ]}
           width={1200}
       >
           {selectedItem && selectedItem.details && (
               <div>
                   <Row gutter={[16, 16]}>
                       <Col span={24}>
                               <Descriptions title="Detalle" bordered>
                                   <Descriptions.Item label="Folio">{selectedItem.invoice_folio}</Descriptions.Item>
                                   <Descriptions.Item label="Periodo">
                                       {selectedItem?.period_month ? months[selectedItem?.period_month-1]: 'N/A'} {selectedItem?.period_year}
                                   </Descriptions.Item>
                                   <Descriptions.Item label="Estado">
                                       {selectedItem.is_paid ? 'Pagado' : 'Pendiente de pago'}
                                   </Descriptions.Item>
                                   <Descriptions.Item label="Fecha de Pago">
                                       {selectedItem.payment_date ? moment(selectedItem.payment_date).format('DD/MM/YYYY') : '--'}
                                   </Descriptions.Item>
                               </Descriptions>
                       </Col>
                       <Col span={24}>
                           <Table
                               columns={detailColumns}
                               dataSource={selectedItem.details}
                               pagination={false}
                               bordered
                               size="small"
                               rowKey="id"
                               summary={pageData => {
                                   const totals = pageData.reduce((acc, current) => ({
                                       subtotal: (parseFloat(acc.subtotal) + parseFloat(current.subtotal)).toFixed(2),
                                       taxes: (parseFloat(acc.taxes) + parseFloat(current.taxes)).toFixed(2),
                                       total: (parseFloat(acc.total) + parseFloat(current.total)).toFixed(2),
                                   }), { subtotal: '0.00', taxes: '0.00', total: '0.00' });

                                   return (
                                       <Table.Summary.Row>
                                           <Table.Summary.Cell colSpan={5}><strong>Totales:</strong></Table.Summary.Cell>
                                           <Table.Summary.Cell>
                                               <NumberFormat suffix={'MXN'} number={totals.subtotal} prefix={'$'}/>
                                           </Table.Summary.Cell>
                                           <Table.Summary.Cell>
                                               <NumberFormat suffix={'MXN'} number={totals.taxes} prefix={'$'}/>
                                           </Table.Summary.Cell>
                                           <Table.Summary.Cell>
                                               <NumberFormat suffix={'MXN'} number={totals.total} prefix={'$'}/>
                                           </Table.Summary.Cell>
                                       </Table.Summary.Row>
                                   );
                               }}
                           />
                       </Col>
                   </Row>
               </div>
           )}
       </Modal>
    </MainLayoutv2>)
}

export default MembershipPage;